import {
  getReq,
  patchReq,
  postReq,
  deleteReq,
  getById,
  putReq,
} from "../../../services/api";

const state = {
  company: {},
  singleDoc:{},
  documents: [],
  companyDocuments: [],
  dispatchGroups: [],
  chargeAccounts: [],
  payAccounts: [],
  expenseAccounts: [],
  documentSetting: {},
  docTypeItems:[],
  defaultDocType:null,
  tags: [],
  assets: [],
  allDocs: [],
  allDriverDocs: null,
};

const getters = {
  company: (state) => state.company,
  documents: (state) => state.documents,
  singleDoc:(state)=>state.singleDoc,
  companyDocuments: (state) => state.companyDocuments,
  dispatchGroups: (state) => state.dispatchGroups,
  chargeAccounts: (state) => state.chargeAccounts,
  payAccounts: (state) => state.payAccounts,
  expenseAccounts: (state) => state.expenseAccounts,
  documentSetting: (state) => state.documentSetting,
  tags: (state) => state.tags,
  assetsData: (state) => state.assets,
  allDocs: (state) => state.allDocs,
  allDriverDocs: (state) => state.allDriverDocs,
  docTypeItems: (state)=>state.docTypeItems,
  defaultDocType: (state)=>state.defaultDocType
};

const actions = {
  async getCompany({ commit }) {
    try {
      const company = await getReq(`app/company/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setCompany", company);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getSingleDoc({ commit }, id) {
    try {
      let document= await getById("app/documents/", id);
      commit("setSingleDoc", document);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllDocuments({ commit }, url) {
    try {
      const data = await getReq(`app/expirydata/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setDocuments", data.results);
      return {records:data.results, count:data.count}
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async exportExpiryData(_, url) {
    try {
      const data = await getReq(`app/expirydata/export_to_csv/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      return data
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllTags({ commit }, id) {
    try {
      const tags = await getReq(`app/add_tags/?company_id=${id}` );
      commit("setTags", tags.sort((a, b) => a.add_tag.toLowerCase().localeCompare(b.add_tag.toLowerCase())));
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
    }
  },
  async getLastRecord(_, {company_id, query}) {
    try {
      const {last_record_no} = await getReq(`app/get-last-record-no/?company_id=${company_id}&${query}`);
      return last_record_no
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
    }
  },
  async deleteCompanyTag(_, id) {
    try {
      return await deleteReq("app/add_tags", id);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateCompanyTag(_, { id, payload }) {
    try {
      return await patchReq("app/add_tags", id, payload);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response.data;
    }
  },
  async sendSamsaraCode(_, formData) {
    try {
      await postReq("integration/get_samsara_code/", formData);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async sendMotiveCode(_, formData) {
    try {
      await postReq("integration/get_motive_code/", formData);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async setSamsaraData() {
    try {
      await postReq("integration/data-samsara/", {});
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async setAssetsData(_, link) {
    try {
      await postReq(`integration/${link}/`, {});
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async setIsaacAccessToken(_, payload) {
    try {
      await postReq(`integration/get_isaac_access_token/`, payload);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response.data;
    }
  },
  async getAssetsData({ commit }) {
    try {
      const { data } = await getReq(`integration/asset_view/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setAssetsData", data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async sendCompanyDocuments(_, formData) {
    try {
      console.log(formData)
      const documents = await postReq("app/documents/", formData);
      return documents;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async sendBulkCompanyDocuments(_, formData) {
    try {
      const {status} = await postReq("app/bulk-document-async/", formData);
      return status;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  async smartDropPlus(_, formData) {
    try {
      const {status} = await postReq("app/document-parser/", formData);
      return status;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  async checkBulkCompanyDocumentsUploadStatus() {
    try {
      const data = await getReq(`app/bulk-document-async/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      return data
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response;
    }
  },
  async updateBulkCompanyDocumentsUploadStatus(_, { id, payload }) {
    try {
      return await patchReq("app/bulk-document-async", id, payload);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async checkSmartDropStatus() {
    try {
     const {lock} = await getReq("app/async-task-lock/");
     return lock
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  
  async updateCompanyDocuments(_, { id, payload }) {
    try {
      return await patchReq("app/documents", id, payload);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async deleteCompanyDocuments(_, id) {
    try {
      return await deleteReq("app/documents", id);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async createCompany(_, { payload, key=null }) {
    try {
      const updatedCompany = await postReq("app/company", payload,key);
      return updatedCompany;
    } catch (e) {
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(e.response.data);
      throw e.response.data;
    }
  },
  async updateCompany({ commit }, { id, payload, key = null }) {
    try {
      const updatedCompany = await patchReq("app/company", id, payload, key);
      commit("setCompany", [updatedCompany]);
      return updatedCompany;
    } catch (e) {
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(e.response.data);
      throw e.response.data;
    }
  },

  async addCompanyTag(_, payload) {
    try {
      const {id} = await postReq(`app/add_tags/`, payload);
      return {id}
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response.data;
    }
  },
  async addDispatchGroup({ commit }, payload) {
    try {
      const dispatchGroup = await postReq(`app/dispatch-group/`, payload);
      commit("addDispatchGroup", dispatchGroup);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async getDispatchGroups({ commit }, id) {
    try {
      const dispatchGroups = await getReq(
        `app/dispatch-group/?company_id=${id}`
      );
      commit("setDispatchGroup", dispatchGroups);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  async deleteDispatchGroup({ commit }, id) {
    try {
      const response = await deleteReq("app/dispatch-group", id+"/");
      commit("deleteDispatchGroup", id);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateDispatchGroup({ commit }, payload) {
    try {
      const response = await putReq("app/dispatch-group", payload.id, payload);
      commit("updateDispatchGroup", payload);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },

  async addChargeAccount({ commit }, payload) {
    try {
      const chargeAccount = await postReq(`app/charge-account/`, payload);
      commit("addChargeAccount", chargeAccount);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async getChargeAccounts({ commit }) {
    try {
      const chargeAccounts = await getReq(`app/charge-account/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setChargeAccount", chargeAccounts);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  async deleteChargeAccount({ commit }, id) {
    try {
      const response = await deleteReq("app/charge-account", id+"/");
      commit("deleteChargeAccount", id);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateChargeAccount({ commit }, payload) {
    try {
      const response = await putReq("app/charge-account", payload.id, payload);
      commit("updateChargeAccount", payload);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async addPayAccount({ commit }, payload) {
    try {
      const payAccount = await postReq(`app/pay-account/`, payload);
      commit("addPayAccount", payAccount);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async getPayAccounts({ commit }) {
    try {
      const payAccounts = await getReq(`app/pay-account/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setPayAccount", payAccounts);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  async deletePayAccount({ commit }, id) {
    try {
      const response = await deleteReq("app/pay-account", id+"/");
      commit("deletePayAccount", id);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updatePayAccount({ commit }, payload) {
    try {
      const response = await putReq("app/pay-account", payload.id, payload);
      commit("updatePayAccount", payload);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },

  async addExpenseAccount({ commit }, payload) {
    try {
      const expenseAccount = await postReq(`app/expense-account/`, payload);
      commit("addExpenseAccount", expenseAccount);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },
  async getExpenseAccounts({ commit }) {
    try {
      const expenseAccounts = await getReq(`app/expense-account/${localStorage.getItem("userSelectedCompany") ? `?company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      commit("setExpenseAccount", expenseAccounts);
      // get all team member again
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response.data);
      throw error.response.data;
    }
  },

  async deleteExpenseAccount({ commit }, id) {
    try {
      const response = await deleteReq("app/expense-account", id+"/");
      commit("deleteExpenseAccount", id);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateExpenseAccount({ commit }, payload) {
    try {
      const response = await putReq("app/expense-account", payload.id, payload);
      commit("updateExpenseAccount", payload);
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getDocumentSettings({ commit }, id) {
    try {
      let documentSetting = await getReq(
        `tms_company/document-settings/?company_id=${id}`
      );
      commit("setDocumentSetting", documentSetting[0]);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getCompanyDetails(_, payload) {
    try {
      const data = await postReq(`app/get-company-details/`, payload);
      return data
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllDocs({ commit }, payload) {
    try {
      const documents = await postReq(`app/get_docs/`, payload);
      commit("setAllDocs", documents);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllDriverDocs({ commit }, payload) {
    try {
      const documents = await postReq(`app/get_all_docs/`, payload);
      console.log(documents);
      commit("setDriverDocs", documents);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateDocumentSettings(_, { payload }) {
    console.log(payload);
    try {
      const response = await putReq(
        `tms_company/document-settings`,
        payload.id,
        payload
      );
      return response;
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllDocTypes({ commit }, url) {
    try {
      const data = await getReq(`app/document-type/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      const defaultID = data.types.filter((item)=>item.type === 'Default')[0].id
      commit("setDocTypes", data.types.sort((a, b) => a.type.toLowerCase().localeCompare(b.type.toLowerCase())));
      commit("setDefaultDoc",defaultID)
      return data.types
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async addDocTypeItem(_, payload) {
    try {
      await postReq("app/document-type/", payload);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
        const errors = error.response.data;
        const docTypeErrors = [];
        for (const key in errors) {
          if (Object.hasOwnProperty.call(errors, key)) {
            docTypeErrors.push(errors[key]);
          }
        }
  
        throw docTypeErrors[0][0];
    }
  },
  async updateDocTypeItem(_, { id, payload }) {
    try {
      await patchReq("app/document-type", id, payload, null, false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = error.response.data;
      const docTypeErrors = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          docTypeErrors.push(errors[key]);
        }
      }

      throw docTypeErrors[0][0];
    }
  },
  async bulkUpdateDocTypeItems(_,payload){
    try {
      await postReq("app/documents-bulk-update-doctype/", payload);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = error.response.data;
      const docTypeErrors = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          docTypeErrors.push(errors[key]);
        }
      }

      throw docTypeErrors[0][0];
    }
  },
  async getAllRequiredDocs(_, url) {
    try {
      const data = await getReq(`app/document-type-report/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page }
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async exportAllRequiredDocsData(_, url) {
    try {
      return await getReq(`app/document-type-report/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getCompanyActivityLogs(_, url) {
    try {
      const data = await getReq(`app/activity-logs/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      return { records: data.data, total_pages: data.total_pages, queryset_count: data.queryset_count, total_entries: data.total_entries_count, current_page: data.current_page }
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getModelActivityLogs(_, url) {
    try {
      const data = await getReq(`app/object-activity-logs/${url ? "?" + url : ""}${localStorage.getItem("userSelectedCompany") ? `${url ? '&' : '?'}company_id=${localStorage.getItem("userSelectedCompany")}` : ''}`);
      return data
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllContacts(_, id) {
    try {
      const data = await getReq(`app/company-contacts/?company_id=${id}` );
      return data.contacts.sort((a, b) => a.order_no - b.order_no);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
    }
  },
  async addContact(_, {id, payload}) {
    try {
      const data = await postReq(`app/company-contacts/?company_id=${id}`, payload );
      return data
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
    }
  },
  async updateContact(_, { id, payload }) {
    try {
      await patchReq("app/company-contacts", id, payload, null, false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = error.response.data;
      const docTypeErrors = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          docTypeErrors.push(errors[key]);
        }
      }

      throw docTypeErrors[0][0];
    }
  },
  async deleteContact(_, id) {
    try {
      return await deleteReq("app/company-contacts", id);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async updateContactOrder(_, payload) {
    try {
      await postReq("app/company-contacts-update-order/", payload);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = error.response.data;
      const docTypeErrors = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          docTypeErrors.push(errors[key]);
        }
      }

      throw docTypeErrors[0][0];
    }
  },
};

const mutations = {
  setCompany: (state, company) => (state.company = company),
  setDocuments: (state, documents) => (state.documents = documents),
  setTags: (state, tags) =>
    (state.tags = [
      ...new Map(tags.map((item) => [item["add_tag"], item])).values(),
    ]),
  setCompanyDocuments: (state, companyDocuments) =>
    (state.companyDocuments = companyDocuments),
  addDispatchGroup: (state, dispatchGroup) =>
    state.dispatchGroups.push(dispatchGroup),
  setSingleDoc: (state, singleDoc) => state.singleDoc = singleDoc,
  setDispatchGroup: (state, dispatchGroups) =>
    (state.dispatchGroups = dispatchGroups),
  deleteDispatchGroup: (state, id) => {
    const index = state.dispatchGroups.findIndex((x) => x.id === id);
    state.dispatchGroups.splice(index, 1);
  },
  updateDispatchGroup: (state, dispatchGroup) => {
    state.dispatchGroups = state.dispatchGroups.map((item) =>
      item.id === dispatchGroup.id ? dispatchGroup : item
    );
  },
  addChargeAccount: (state, chargeAccount) =>
    state.chargeAccounts.push(chargeAccount),
  setChargeAccount: (state, chargeAccounts) =>
    (state.chargeAccounts = chargeAccounts),
  deleteChargeAccount: (state, id) => {
    const index = state.chargeAccounts.findIndex((x) => x.id === id);
    state.chargeAccounts.splice(index, 1);
  },
  updateChargeAccount: (state, chargeAccount) => {
    state.chargeAccounts = state.chargeAccounts.map((item) =>
      item.id === chargeAccount.id ? chargeAccount : item
    );
  },
  addPayAccount: (state, payAccount) => state.payAccounts.push(payAccount),
  setPayAccount: (state, payAccounts) => (state.payAccounts = payAccounts),
  deletePayAccount: (state, id) => {
    const index = state.payAccounts.findIndex((x) => x.id === id);
    state.payAccounts.splice(index, 1);
  },
  updatePayAccount: (state, payAccount) => {
    state.payAccounts = state.payAccounts.map((item) =>
      item.id === payAccount.id ? payAccount : item
    );
  },
  addExpenseAccount: (state, expenseAccount) =>
    state.expenseAccounts.push(expenseAccount),
  setExpenseAccount: (state, expenseAccounts) =>
    (state.expenseAccounts = expenseAccounts),
  deleteExpenseAccount: (state, id) => {
    const index = state.expenseAccounts.findIndex((x) => x.id === id);
    state.expenseAccounts.splice(index, 1);
  },
  updateExpenseAccount: (state, expenseAccount) => {
    state.expenseAccounts = state.expenseAccounts.map((item) =>
      item.id === expenseAccount.id ? expenseAccount : item
    );
  },
  setDocumentSetting: (state, documentSetting) => {
    state.documentSetting = documentSetting;
  },
  setAssetsData: (state, data) => (state.assets = data),
  setAllDocs: (state, documents) => (state.allDocs = documents),
  setDriverDocs: (state, documents) => (state.allDriverDocs = documents),
  setDocTypes: (state, docTypes) =>(state.docTypeItems = docTypes),
  setDefaultDoc: (state, defaultDoc) =>(state.defaultDocType = defaultDoc)
};

export default {
  state,
  getters,
  actions,
  mutations,
};
