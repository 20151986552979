<template>
    <div class="bg-white rounded">
        <h3 class="font-weight-bold px-3 f-20 pt-4 text-left mb-3 text-black">General</h3>
        <b-form class="pb-3 px-3">
            <b-row class="mb-4">
                <b-col lg="7" md="12" sm="12">
                    <div class="upload-btn-wrapper mt-2 text-black text-black" :class="{ 'h-auto': companyLogoUrl }">
                        <b-img :src="companyLogoUrl
                            ? companyLogoUrl
                            : require('@/assets/upload_logo.svg')
                            " :class="{
        img_custom: companyLogoUrl,
    }"></b-img>
                        <button v-if="!companyLogoUrl" class="foo">
                            Drop your logo here, or browse
                        </button>
                        <input ref="imageFile" type="file" name="imageFile" id="logoUpload" v-on:change="handleImageUpload"
                            :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                $store.state.user.user.team_member.role !== 'Operations'" accept="image/*"/>
                    </div>
                    <div class="buttons text-left" v-if="$store.state.user.user &&
                        ($store.state.user.user.team_member.role === 'Admin' ||
                            $store.state.user.user.team_member.role === 'Operations')
                        ">
                        <b-button btn class="mt-2 cancel" @click="resetImage">Reset</b-button>
                        <b-button @click="$refs.imageFile.click()" btn class="ml-3 mt-2 save"
                            variant="primary">Upload</b-button>
                    </div>
                </b-col>
            </b-row>
            <hr class="my-0" />
            <b-row class="mt-3">
                <b-col lg="7" md="12" sm="12">
                    <b-row class="text-left mb-3">
                        <b-col sm="12" class="my-auto">
                            <label>Company Name:</label>
                        </b-col>
                        <b-col sm="12">
                            <b-form-input v-model="company[0].name" disabled
                                placeholder="Enter Company Name"></b-form-input>
                            <p v-if="errors && errors.name" class="field-error mb-lg-3">
                                {{ errors.name[0] }}
                            </p>
                        </b-col>
                    </b-row>
                    <b-row class="text-left mb-3">
                        <b-col sm="12" class="my-auto">
                            <label>Address:</label>
                        </b-col>
                        <b-col sm="12">
                            <b-form-input v-model="company[0].address" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                $store.state.user.user.team_member.role !== 'Operations'"
                                placeholder="Enter Address"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="text-left mb-3">
                        <b-col sm="12" class="my-auto">
                            <label>City:</label>
                        </b-col>
                        <b-col sm="12">
                            <b-form-input v-model="company[0].city" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                $store.state.user.user.team_member.role !== 'Operations'"
                                placeholder="Enter City"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="text-left mb-3">
                        <b-col sm="12" class="my-auto">
                            <label>State:</label>
                        </b-col>
                        <b-col sm="12">
                            <v-select class="bg_white w-100" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                $store.state.user.user.team_member.role !== 'Operations'" v-model="company[0].state.id"
                                :options="states" item-value="id" item-text="label" :reduce="(option) => option.id" />
                        </b-col>
                    </b-row>

                    <b-row class="text-left mb-3">
                        <b-col sm="12" class="my-auto">
                            <label>Postal Code:</label>
                        </b-col>
                        <b-col sm="12">
                            <b-form-input v-model="company[0].zipcode" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                $store.state.user.user.team_member.role !== 'Operations'"
                                placeholder="Enter Postal Code"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="text-left mb-3">
                        <b-col sm="12" class="my-auto">
                            <label>Phone Number:</label>
                        </b-col>
                        <b-col sm="12">
                            <vue-tel-input v-bind="bindProps" class="w-100 f-14" @validate="checkNum"
                                v-model="company[0].phone" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></vue-tel-input>
                            <p v-if="errors && errors.phone" class="field-error mb-lg-3">
                                {{ errors.phone[0] }}
                            </p>
                        </b-col>
                    </b-row>
                    <!-- <b-row class="text-left mb-3">
                        <b-col sm="12" class="my-auto">
                            <label>Email:</label>
                        </b-col>
                        <b-col sm="12">
                            <b-form-input v-model="company[0].email" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                      $store.state.user.user.team_member.role !== 'Operations'" placeholder="Enter Email"></b-form-input>
                            <b-icon class="position-absolute" icon="envelope"></b-icon>
                            <p v-if="errors && errors.email" class="field-error mb-lg-3">
                                {{ errors.email[0] }}
                            </p>
                        </b-col>
                    </b-row> -->
                    <div class="buttons text-left">
                        <b-button btn class="mt-3 cancel" to="/">Close</b-button>
                        <b-button v-if="$store.state.user.user.team_member.role === 'Admin' ||
                            $store.state.user.user.team_member.role === 'Operations'" @click="updateCompanyData" btn
                            class="ml-3 mt-3 save" variant="primary">Save</b-button>
                    </div>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
export default {
    name: "General",
    components: { vSelect },
    data() {
        return {
            companyLogo: null,
            companyLogoUrl: null,
            qrCodeUrl: "",
            bindProps: {
                mode: "international",
                autoFormat: true,
                preferredCountries: ["US", "CA", "MX"],
                inputOptions: {
                    placeholder: "Enter a phone no.",
                    maxlength: 30,
                },
                dropdownOptions: {
                    showSearchBox: true,
                    showDialCodeInList: true,
                    showFlags: true,
                    showDialCodeInSelection: true,
                    width: "300px",
                },
            },
            states: [],
            countries: [],
            isPhoneValid: false,
            errors: {
                date: null,
                type: null,
                document: null,
                description: null,
                phone: null,
            }
        }
    },
    methods: {
        ...mapActions(["updateCompany", 'setNotification', "getAllCountries", "setLoader"]),
        handleImageUpload() {
            this.companyLogo = this.$refs.imageFile.files[0];
            this.companyLogoUrl = URL.createObjectURL(this.companyLogo);
        },
        checkNum(phoneObject) {
            if (phoneObject.valid !== undefined) {
                this.isPhoneValid = phoneObject.valid;
            }
        },
        resetImage() {
            this.companyLogo = null
            this.companyLogoUrl = null
            this.updateCompanyData(true)
        },
        payloadToFormData(payload) {
            let formData = new FormData();
            for (let [key, value] of Object.entries(payload)) {
                if (key === "logo" && this.companyLogo) {
                    formData.append(key, this.companyLogo, this.companyLogo.name);
                }
                else {
                    formData.append(key, value);
                }

            }
            return formData;
        },
        async updateCompanyData(removeImage = false) {
            try {
                this.setLoader(true)
                this.errors = {
                    date: null,
                    type: null,
                    document: null,
                    description: null,
                    phone: null,
                };
                if (this.company[0].phone) {
                    if (!this.isPhoneValid) {
                        throw {
                            phone: ["Wrong Format"],
                        };
                    }
                }

                const validationFields = ["name", "email"];
                let errors = {};
                validationFields.forEach((e) => {
                    if (!this.company[0][e]) {
                        errors[e] = ["This field is required."];
                    }
                });
                if (Object.keys(errors).length > 0) {
                    throw errors;
                }
                let companyData = {
                    ...this.company[0],
                    state: this.company[0].state.id,
                    company_id: this.userCompany,
                    ...removeImage ? { logo: "" } : {},
                    motive_access_token_expiry : this.company[0].motive_access_token_expiry ? this.company[0].motive_access_token_expiry : ''
                };
                console.log(companyData)
                const formData = this.payloadToFormData(companyData);
                const updatedCompany = await this.updateCompany({
                    id: `${this.userCompany}`,
                    payload: formData,
                });
                if (updatedCompany) {
                    this.company[0] = updatedCompany;
                    await this.setNotification({
                        msg: `Successfully updated ${this.company[0].name}`,
                        type: "success",
                        color: "green",
                    });
                }
                this.setLoader(false)
            } catch (error) {
                this.setLoader(false)
                console.log(error);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        async getCountries() {
            await this.getAllCountries();
            this.countries = this.allCountries.map((item) => ({
                id: item.id,
                label: item.name,
            }));
            this.getStates(this.countries[0]);
        },
        getStates(selectedOption) {
            this.states = this.allCountries
                .filter((country) => country.id === selectedOption.id)[0]
                .state.map((item) => ({
                    id: item.id,
                    label: item.name,
                }));
            if (this.states.length === 0) {
                this.company[0].state.id = null;
            }
        },
    },
    computed: {
        ...mapGetters(['company', "allCountries", "userCompany"])
    },
    watch: {
        company: {
            async handler() {
                this.companyLogoUrl = this.company[0].logo
                await this.getCountries();
                if (!this.company[0].state) {
                    this.company[0].state = {
                        id: this.states[0].id,
                        name: null,
                        country: 1,
                    };
                }
            },
            immediate: true,
        }
    },

}
</script>
<style>