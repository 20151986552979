var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded"},[_c('h3',{staticClass:"font-weight-bold px-3 f-20 pt-4 text-left mb-3 text-black"},[_vm._v("Settings")]),_c('b-form',{staticClass:"pb-3 px-3"},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"lg":"7","md":"12","sm":"12"}},[_c('b-row',{staticClass:"text-left mb-3"},[_c('b-col',{staticClass:"my-auto",attrs:{"sm":"12"}},[_c('p',{staticClass:"font-weight-bold mb-2"},[_vm._v("Company ID")]),_c('label',[_vm._v("Company ID:")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.company[0].company_ID),callback:function ($$v) {_vm.$set(_vm.company[0], "company_ID", $$v)},expression:"company[0].company_ID"}})],1)],1)],1)],1),_c('hr',{staticClass:"mt-2"}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"lg":"7","md":"12","sm":"12"}},[_c('b-row',{staticClass:"text-left mb-2"},[_c('b-col',{staticClass:"my-auto",attrs:{"sm":"12"}},[_c('p',{staticClass:"font-weight-bold mb-2"},[_vm._v("Company Equipment Portal")]),_c('label',[_vm._v("Company Path:")])]),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"sm":"12"}},[_c('b-form-input',{attrs:{"placeholder":"Enter Company Path","readonly":""},on:{"click":function($event){return _vm.copyURL(_vm.companyPath)}},model:{value:(_vm.companyPath),callback:function ($$v) {_vm.companyPath=$$v},expression:"companyPath"}}),((_vm.$store.state.user.user.team_member.role === 'Admin' ||
                            _vm.$store.state.user.user.team_member.role === 'Operations') && _vm.companyPath)?_c('b-button',{staticClass:"ml-2 h-40px",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.generateCompanyQRCode(_vm.company[0].name + '-qrcode', _vm.qrCodeUrl)}}},[_vm._v("QR")]):_vm._e()],1)],1),_c('b-row',{staticClass:"text-left mb-2"},[_c('b-col',{staticClass:"my-auto",attrs:{"sm":"12"}},[_c('label',[_vm._v("Company PIN:")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-input',{attrs:{"placeholder":"Enter Pin Code","disabled":""},model:{value:(_vm.company[0].pin_code),callback:function ($$v) {_vm.$set(_vm.company[0], "pin_code", $$v)},expression:"company[0].pin_code"}})],1)],1)],1)],1),_c('hr'),_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"7","md":"12","sm":"12"}},[_c('p',{staticClass:"mb-2 font-weight-bold text-left"},[_vm._v("Driver App")]),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"text-left",attrs:{"sm":"12"}},[_c('v-app',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"text-left w-fit-content"},'label',attrs,false),on),[_vm._v("Lock Vehicle:")])]}}])},[_vm._v(" Will apply to newly added drivers. ")])],1),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.company[0].lock_vehicle),callback:function ($$v) {_vm.$set(_vm.company[0], "lock_vehicle", $$v)},expression:"company[0].lock_vehicle"}})],1),_c('b-col',{staticClass:"mt-3 text-left",attrs:{"sm":"12"}},[_c('v-app',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"text-left w-fit-content"},'label',attrs,false),on),[_vm._v("Lock Trailer:")])]}}])},[_vm._v(" Will apply to newly added drivers. ")])],1),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.company[0].lock_trailer),callback:function ($$v) {_vm.$set(_vm.company[0], "lock_trailer", $$v)},expression:"company[0].lock_trailer"}})],1),_c('b-col',{staticClass:"mt-3 text-left",attrs:{"sm":"12"}},[_c('v-app',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"text-left w-fit-content"},'label',attrs,false),on),[_vm._v("Allow Driver Docs: ")])]}}])},[_vm._v(" Allow drivers to add driver documents in mobile app. ")])],1),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.company[0].allow_driver_docs),callback:function ($$v) {_vm.$set(_vm.company[0], "allow_driver_docs", $$v)},expression:"company[0].allow_driver_docs"}})],1),_c('b-col',{staticClass:"mt-3 text-left",attrs:{"sm":"12"}},[_c('v-app',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"text-left w-fit-content"},'label',attrs,false),on),[_vm._v("Allow Vehicle Docs: ")])]}}])},[_vm._v(" Allow drivers to add vehicle documents in mobile app. ")])],1),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.company[0].allow_vehicle_docs),callback:function ($$v) {_vm.$set(_vm.company[0], "allow_vehicle_docs", $$v)},expression:"company[0].allow_vehicle_docs"}})],1),_c('b-col',{staticClass:"mt-3 text-left",attrs:{"sm":"12"}},[_c('v-app',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"text-left w-fit-content"},'label',attrs,false),on),[_vm._v("Allow Trailer Docs: ")])]}}])},[_vm._v(" Allow drivers to add trailer documents in mobile app. ")])],1),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.company[0].allow_trailer_docs),callback:function ($$v) {_vm.$set(_vm.company[0], "allow_trailer_docs", $$v)},expression:"company[0].allow_trailer_docs"}})],1),_c('b-col',{staticClass:"mt-3 text-left",attrs:{"sm":"12"}},[_c('v-app',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"text-left w-fit-content"},'label',attrs,false),on),[_vm._v("File Drop: ")])]}}])},[_vm._v(" Allow drivers to drop any file. ")])],1),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.showFileDrop),callback:function ($$v) {_vm.showFileDrop=$$v},expression:"showFileDrop"}})],1),_c('b-col',{staticClass:"mt-3 text-left",attrs:{"sm":"12"}},[_c('v-app',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"text-left w-fit-content"},'label',attrs,false),on),[_vm._v("Dot Mode: ")])]}}])},[_vm._v(" Allow drivers to use DOT Mode in mobile app. ")])],1),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.company[0].show_dot_mode),callback:function ($$v) {_vm.$set(_vm.company[0], "show_dot_mode", $$v)},expression:"company[0].show_dot_mode"}})],1),_c('b-col',{staticClass:"mt-3 text-left",attrs:{"sm":"12"}},[_c('v-app',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"text-left w-fit-content"},'label',attrs,false),on),[_vm._v("Contacts: ")])]}}])},[_vm._v(" Allow drivers to view company contacts. ")])],1),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.company[0].contacts),callback:function ($$v) {_vm.$set(_vm.company[0], "contacts", $$v)},expression:"company[0].contacts"}})],1),_c('b-col',{staticClass:"mt-3 text-left",attrs:{"sm":"12"}},[_c('v-app',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({staticClass:"text-left w-fit-content"},'label',attrs,false),on),[_vm._v("Hide Trailer: ")])]}}])},[_vm._v(" Hide Trailers from all drivers in mobile app. ")])],1),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.company[0].hide_trailer),callback:function ($$v) {_vm.$set(_vm.company[0], "hide_trailer", $$v)},expression:"company[0].hide_trailer"}})],1)],1)],1)],1),_c('hr',{staticClass:"my-0"}),_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"7","md":"12","sm":"12"}},[_c('p',{staticClass:"mb-2 font-weight-bold text-left"},[_vm._v("Models")]),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"text-left",attrs:{"sm":"12"}},[_c('label',{staticClass:"text-left w-fit-content"},[_vm._v("Maintenance:")]),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.showMaintenance),callback:function ($$v) {_vm.showMaintenance=$$v},expression:"showMaintenance"}})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"text-left",attrs:{"sm":"12"}},[_c('label',{staticClass:"text-left w-fit-content"},[_vm._v("Safety:")]),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.showSafety),callback:function ($$v) {_vm.showSafety=$$v},expression:"showSafety"}})],1)],1)],1)],1),_c('hr',{staticClass:"my-0"}),_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"7","md":"12","sm":"12"}},[_c('p',{staticClass:"mb-2 font-weight-bold text-left"},[_vm._v("Doc Type")]),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"text-left",attrs:{"sm":"12"}},[_c('label',{staticClass:"text-left w-fit-content"},[_vm._v("Doc Type Required:")]),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"name":"check-button","switch":"","size":"lg","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},model:{value:(_vm.docTypeRequired),callback:function ($$v) {_vm.docTypeRequired=$$v},expression:"docTypeRequired"}})],1)],1)],1)],1),_c('div',{staticClass:"buttons text-left mt-3 d-flex align-items-center"},[_c('b-button',{staticClass:"mx-0 h-40px w-100px",attrs:{"to":"/","variant":"secondary"}},[_vm._v("Close")]),(_vm.$store.state.user.user.team_member.role === 'Admin' ||
                _vm.$store.state.user.user.team_member.role === 'Operations')?_c('b-button',{staticClass:"save mx-0 mb-0 h-40px w-100px  ml-3",attrs:{"btn":"","variant":"primary"},on:{"click":_vm.updateCompanyData}},[_vm._v("Save")]):_vm._e()],1)],1),(_vm.$store.state.user.user.team_member.role === 'Admin' ||
        _vm.$store.state.user.user.team_member.role === 'Operations')?_c('qriously',{ref:"qrcode",staticClass:"d-none",attrs:{"value":'https://app.digitalpermitbook.com/company/' + _vm.company[0].path,"size":200}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }