<template>
  <div v-if="user && user.team_member">
    <header class="text-left search_equipment d-flex user-detail row">
      <div class="col-md-8 d-flex justify-content-between mx-3 mx-md-auto align-items-center">
        <b-img :src="require('@/assets/form-logo.png')" width="143" height="37"></b-img>
        <div>
          <b-dropdown id="dropdown-right" right class="mx-2 dropdown dropdown-dark-section header-section-dropdown">
            <template slot="button-content">
              <b-img v-if="user.team_member.profile_picture" class="userSmallImage rounded-circle"
                :src="user.team_member.profile_picture"></b-img>
              <div v-else class="user-default-img">
                {{ user.first_name.charAt(0).toUpperCase() }}{{ user.last_name.charAt(0).toUpperCase() }}
              </div>
            </template>
            <b-dropdown-item><span class="font-weight-bold f-14">{{ user.first_name }} {{ user.last_name }}</span>
              <br /> <span class="f-12">{{ user.email }}</span>
            </b-dropdown-item>
            <hr class="my-2" />
            <b-dropdown-item to="/profile-page"><span class="f-14">View Profile</span></b-dropdown-item>
            <b-dropdown-item to="/all-companies"><span class="f-14">View Companies</span></b-dropdown-item>
            <hr class="my-2" />
            <b-dropdown-item @click="OnlogoutUser"><span class="f-14">Logout</span></b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </header>
    <b-row>
      <b-col md="8" class="mx-3 mx-md-auto pt-64">
        <h1 class="font-weight-bold f-32 text-left mt-3 mb-3">My Profile</h1>
        <b-row>
          <b-col cols="12">
            <div class="profile-form bg-white p-4 rounded">
              <b-row class="align-items-center">
                <!-- <b-col lg="6" md="12">
                  <b-img class="left_img rounded-circle" :src="memberImageURL
                    ? memberImageURL
                    : user.team_member.profile_picture
                      ? user.team_member.profile_picture
                      : require('@/assets/user.png')
                    " />
                  <div class="upload_btn">
                    <input ref="imageFile" type="file" name="imageFile" class="imageFile" @change="handleImageUpload"
                      accept="image/*" />
                    <b-button class="upload text-center mt-2" variant="primary">Upload </b-button>
                  </div>
                </b-col> -->
                <b-col sm="12" class="mb-3">
                  <h5 class="font-weight-bold font-18 text-left">General</h5>
                  <b-row>
                    <b-col sm="6">
                      <b-form-group label="First Name" class="text-left mb-3">
                        <b-form-input type="text" v-model="user.first_name" placeholder="Enter First Name"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="6">
                      <b-form-group label="Last Name" class="text-left mb-3">
                        <b-form-input type="text" v-model="user.last_name" placeholder="Enter Last Name"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="6">
                      <b-form-group label="Email" class="text-left mb-3">
                        <b-form-input type="text" v-model="user.email" placeholder="Enter Email Address"
                          disabled></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="6">
                      <b-form-group label="Phone" class="text-left mb-0">
                        <vue-tel-input v-bind="bindProps" class="f-14" v-model="userPhoneNumber" @validate="checkNum" />
                        <p v-if="errors && errors.phone" class="field-error">
                          {{ errors.phone[0] }}
                        </p>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div class="profile-form bg-white p-4 rounded my-3">
              <h5 class="font-weight-bold font-18 text-left">Notifications</h5>
              <b-row>
                <b-col md="12" class="mb-3">
              <div class="text-left">
                <label>Email Expiry Data</label>
                <b-form-checkbox name="check-button" class="ml-2" switch size="lg"
                  v-model="user.team_member.email_expiry_date" />
              </div>
              </b-col>
              <b-col md="12" class="mb-3">
              <div class="text-left">
                <label>Document Added Email</label>
                <b-form-checkbox name="check-button" class="ml-2" switch size="lg"
                  v-model="user.team_member.get_document_added_email" />
              </div>
              </b-col>
              <b-col md="12" class="mb-3">
              <div class="text-left">
                <label>DOT Mode Email</label>
                <b-form-checkbox name="check-button" class="ml-2" switch size="lg"
                  v-model="user.team_member.get_driver_dot_mode_email" />
              </div>
              </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col>
                  <div class="profile_btn d-flex justify-content-between align-items-center">
                    <b-button class="mt-3 h-40px m-0" variant="outline-primary" @click="$bvModal.show('change-password')">Change
                      Password</b-button>
                    <div>
                      <b-button to="/" class="mx-0 h-40px w-100px mt-3 mr-3"
                            variant="secondary">Close</b-button> 
                      <b-button class="mt-3 save h-40px w-100px" variant="primary" type="button"
                        @click="updateUserDetails()">Save</b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal id="change-password" centered hide-footer no-close-on-backdrop
    no-close-on-esc
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Change Password
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <b-row>
        <b-col cols="12">
          <b-form @submit.prevent="updatePassword">
            <b-row>
              <b-col lg="12">
                <b-form-group label-for="password-1" label="Old Password" class="text-left password mb-2">
                  <b-form-input :type="showPassword1 ? 'text' : 'password'" id="password-1"
                    placeholder="Enter Old Password" v-model="passwords.user.old_password" required></b-form-input>
                  <b-icon :icon="showPassword1 ? 'eye-fill' : 'eye-slash-fill'" @click="showPassword1 = !showPassword1"
                    style="fill:black"></b-icon>
                </b-form-group>
              </b-col>

              <b-col lg="12">
                <b-form-group label="New Password" label-for="password-2" class="text-left password mb-2">
                  <b-form-input :type="showPassword2 ? 'text' : 'password'" id="password-2"
                    placeholder="Enter New Password" v-model="passwords.user.new_password" required></b-form-input>
                  <b-icon :icon="showPassword2 ? 'eye-fill' : 'eye-slash-fill'" @click="showPassword2 = !showPassword2"
                    style="fill:black"></b-icon>
                </b-form-group>
              </b-col>

              <b-col lg="12">
                <b-form-group label="Confirm Password" label-for="password-3" class="text-left password  mb-2">
                  <b-form-input :type="showPassword3 ? 'text' : 'password'" id="password-3"
                    placeholder="Enter Confirm Password" v-model="passwords.user.confirm_password"
                    required></b-form-input>
                  <b-icon :icon="showPassword3 ? 'eye-fill' : 'eye-slash-fill'" @click="showPassword3 = !showPassword3"
                    style="fill:black"></b-icon>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="profile_btn ml-2 d-flex justify-content-end align-items-center">
                    <b-button class="mx-0 h-40px w-100px mt-3 mr-3 m-0" @click="$bvModal.hide('change-password')"
                            variant="secondary">Cancel</b-button> 
                  <b-button class="mt-3 save h-40px w-100px m-0" variant="primary" type="submit">Save</b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isPhoneValid: false,
      showPassword1: false,
      showPassword2: false,
      showPassword3: false,
      memberImage: null,
      memberImageURL: null,
      bindProps: {
        mode: "international",
        autoFormat: true,
        preferredCountries: ["US", "CA", "MX"],
        inputOptions: {
          placeholder: "Enter a phone no.",
          maxlength: 30,
        },
        dropdownOptions: {
          showSearchBox: true,
          showDialCodeInList: true,
          showFlags: true,
          showDialCodeInSelection: true,
          width: "300px",
        },
      },
      passwords: {
        user: {
          old_password: null,
          new_password: null,
          confirm_password: null,
        },
      },

      errors: {
        phone: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    userPhoneNumber: {
      get: function () {
        return this.user.team_member.phone ? this.user.team_member.phone : ''
      },
      set: function (newValue) {
        this.user.team_member.phone = newValue
      }
    }
  },
  methods: {
    ...mapActions(["getUserByID", "updateTeamMember", "setNotification", "logoutUser"]),
    checkNum(phoneObject) {
      if (phoneObject.valid !== undefined) {
        this.isPhoneValid = phoneObject.valid;
      }
    },
    OnlogoutUser() {
      this.logoutUser();
      this.$router.push("/login");
    },
    async updateUserDetails() {
      try {
        if (this.user.team_member.phone) {
          if (!this.isPhoneValid) {
            throw {
              phone: ["Wrong format"],
            };
          }
        }
        await this.updateTeamMember({
          id: this.user.team_member.id,
          payload: {
            phone: this.user.team_member.phone, email_expiry_date: this.user.team_member.email_expiry_date,
            get_document_added_email: this.user.team_member.get_document_added_email,
            get_driver_dot_mode_email: this.user.team_member.get_driver_dot_mode_email,
            user: {
              first_name: this.user.first_name,
              last_name: this.user.last_name
            }
          },
        });
        await this.setNotification({
          msg: "User Details Updated!!",
          type: "success",
          color: "green",
        });
        this.$router.push("/dashboard")
        this.errors = {
          phone: null,
        };
      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
    async updatePassword() {
      if (
        this.passwords.user.new_password === this.passwords.user.old_password
      ) {
        await this.setNotification({
          msg: "Old Password can't be used as a New Password",
          type: "error",
          color: "red",
        });
      } else if (
        this.passwords.user.new_password ===
        this.passwords.user.confirm_password
      ) {
        try {
          await this.updateTeamMember({
            id: this.user.team_member.id,
            payload: this.passwords,
          });
          await this.setNotification({
            msg: "Successfully Password Updated!!",
            type: "success",
            color: "green",
          });
          this.$bvModal.hide("change-password");
        } catch (error) {
          await this.setNotification({
            msg: error,
            type: "error",
            color: "red",
          });
        }
      } else {
        await this.setNotification({
          msg: "Password doesn't match.",
          type: "error",
          color: "red",
        });
      }
    },
    handleImageUpload() {
      this.memberImage = this.$refs.imageFile.files[0];
      this.memberImageURL = URL.createObjectURL(this.memberImage);
      this.updateTeamMemberImage();
    },
    async updateTeamMemberImage() {
      try {
        let formData = new FormData();
        formData.append(
          "profile_picture",
          this.memberImage,
          this.memberImage.name
        );
        await this.updateTeamMember({
          id: this.user.team_member.id,
          payload: formData,
        });
        if (this.user) {
          await this.setNotification({
            msg: `Successfully updated Profile Picture of ${this.user.first_name} ${this.user.last_name}`,
            type: "success",
            color: "green",
          });
        }
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
  },
  async mounted() {
    try {
      await this.getUserByID();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.profile-form,
.password-section {
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%);
}

.profile-form .left_img {
  width: 220px;
  height: 210px;
  margin: 3px;
  border: 3px solid lightgrey;
}

.profile-page .heading {
  padding-bottom: calc(0.175rem + 1px);
}

.password-section {
  padding: 26px 27px 38px 36px;
}

.profile-page h4 {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .upload_btn {
    margin-bottom: 16px;
  }
}

@media (max-width: 575px) {

  .password-section,
  .profile-form {
    padding: 26px 20px 38px 20px !important;
  }
}
</style>
