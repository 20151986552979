<template>
    <div class="bg-white rounded">
        <h3 class="font-weight-bold px-3 f-20 pt-4 text-left mb-3 text-black">Settings</h3>
        <b-form class="pb-3 px-3">
            <b-row class="mt-2">
                <b-col lg="7" md="12" sm="12">
                    <b-row class="text-left mb-3">
                        <b-col sm="12" class="my-auto">
                            <p class="font-weight-bold mb-2">Company ID</p>
                            <label>Company ID:</label>
                        </b-col>
                        <b-col sm="12">
                            <b-form-input v-model="company[0].company_ID" disabled></b-form-input>
                        </b-col>
                    </b-row>
                </b-col>

            </b-row>
            <hr class="mt-2" />
            <b-row class="mt-3">
                <b-col lg="7" md="12" sm="12">
                    <b-row class="text-left mb-2">
                        <b-col sm="12" class="my-auto">
                            <p class="font-weight-bold mb-2">Company Equipment Portal</p>
                            <label>Company Path:</label>
                        </b-col>
                        <b-col sm="12" class="d-flex align-items-center">
                            <b-form-input v-model="companyPath" placeholder="Enter Company Path" readonly
                                @click="copyURL(companyPath)"></b-form-input>
                            <b-button v-if="($store.state.user.user.team_member.role === 'Admin' ||
                                $store.state.user.user.team_member.role === 'Operations') && companyPath"
                                variant="primary" class="ml-2 h-40px"
                                @click="generateCompanyQRCode(company[0].name + '-qrcode', qrCodeUrl)">QR</b-button>
                        </b-col>
                    </b-row>
                    <b-row class="text-left mb-2">
                        <b-col sm="12" class="my-auto">
                            <label>Company PIN:</label>
                        </b-col>
                        <b-col sm="12">
                            <b-form-input v-model="company[0].pin_code" placeholder="Enter Pin Code"
                                disabled></b-form-input>
                        </b-col>
                    </b-row>

                </b-col>
            </b-row>
            <hr />
            <b-row class="my-2">
                <b-col lg="7" md="12" sm="12">
                    <p class="mb-2 font-weight-bold text-left">Driver App</p>
                    <b-row class="mb-3">
                        <b-col sm="12" class="text-left">
                            <v-app>
                                <v-tooltip bottom :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'">
                                    <template #activator="{ on, attrs }">
                                        <label class="text-left w-fit-content" v-on="on" v-bind="attrs">Lock
                                            Vehicle:</label>
                                    </template> Will apply to newly added drivers.
                                </v-tooltip>
                            </v-app>
                            <b-form-checkbox v-model="company[0].lock_vehicle" name="check-button" switch size="lg"
                                class="ml-2" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                        <b-col sm="12" class="mt-3 text-left">
                            <v-app>
                                <v-tooltip bottom :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'">
                                    <template #activator="{ on, attrs }">
                                        <label class="text-left w-fit-content" v-on="on" v-bind="attrs">Lock
                                            Trailer:</label>
                                    </template> Will apply to newly added drivers.
                                </v-tooltip>
                            </v-app>
                            <b-form-checkbox v-model="company[0].lock_trailer" name="check-button" switch size="lg"
                                class="ml-2" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                        <b-col sm="12" class="mt-3 text-left">
                            <v-app>
                                <v-tooltip bottom :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'">
                                    <template #activator="{ on, attrs }">
                                        <label class="text-left w-fit-content" v-on="on" v-bind="attrs">Allow Driver Docs:
                                        </label>
                                    </template> Allow drivers to add driver documents in mobile app.
                                </v-tooltip>
                            </v-app>
                            <b-form-checkbox v-model="company[0].allow_driver_docs" name="check-button" switch size="lg"
                                class="ml-2" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                        <b-col sm="12" class="mt-3 text-left">
                            <v-app>
                                <v-tooltip bottom :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'">
                                    <template #activator="{ on, attrs }">
                                        <label class="text-left w-fit-content" v-on="on" v-bind="attrs">Allow Vehicle Docs:
                                        </label>
                                    </template> Allow drivers to add vehicle documents in mobile app.
                                </v-tooltip>
                            </v-app>
                            <b-form-checkbox v-model="company[0].allow_vehicle_docs" name="check-button" switch size="lg"
                                class="ml-2" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                        <b-col sm="12" class="mt-3 text-left">
                            <v-app>
                                <v-tooltip bottom :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'">
                                    <template #activator="{ on, attrs }">
                                        <label class="text-left w-fit-content" v-on="on" v-bind="attrs">Allow Trailer Docs:
                                        </label>
                                    </template> Allow drivers to add trailer documents in mobile app.
                                </v-tooltip>
                            </v-app>
                            <b-form-checkbox v-model="company[0].allow_trailer_docs" name="check-button" switch size="lg"
                                class="ml-2" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                        <b-col sm="12" class="mt-3 text-left">
                            <v-app>
                                <v-tooltip bottom :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'">
                                    <template #activator="{ on, attrs }">
                                        <label class="text-left w-fit-content" v-on="on" v-bind="attrs">File Drop:
                                        </label>
                                    </template> Allow drivers to drop any file.
                                </v-tooltip>
                            </v-app>
                            <b-form-checkbox v-model="showFileDrop" name="check-button" switch size="lg"
                                class="ml-2" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                        <b-col sm="12" class="mt-3 text-left">
                            <v-app>
                                <v-tooltip bottom :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'">
                                    <template #activator="{ on, attrs }">
                                        <label class="text-left w-fit-content" v-on="on" v-bind="attrs">Dot Mode:
                                        </label>
                                    </template> Allow drivers to use DOT Mode in mobile app.
                                </v-tooltip>
                            </v-app>
                            <b-form-checkbox v-model="company[0].show_dot_mode" name="check-button" switch size="lg"
                                class="ml-2" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                        <b-col sm="12" class="mt-3 text-left">
                            <v-app>
                                <v-tooltip bottom :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'">
                                    <template #activator="{ on, attrs }">
                                        <label class="text-left w-fit-content" v-on="on" v-bind="attrs">Contacts:
                                        </label>
                                    </template> Allow drivers to view company contacts.
                                </v-tooltip>
                            </v-app>
                            <b-form-checkbox v-model="company[0].contacts" name="check-button" switch size="lg"
                                class="ml-2" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                        <b-col sm="12" class="mt-3 text-left">
                            <v-app>
                                <v-tooltip bottom :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'">
                                    <template #activator="{ on, attrs }">
                                        <label class="text-left w-fit-content" v-on="on" v-bind="attrs">Hide Trailer:
                                        </label>
                                    </template> Hide Trailers from all drivers in mobile app.
                                </v-tooltip>
                            </v-app>
                            <b-form-checkbox v-model="company[0].hide_trailer" name="check-button" switch size="lg"
                                class="ml-2" :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                    </b-row>

                </b-col>
            </b-row>
            <hr class="my-0" />
            <b-row class="my-2">
                <b-col lg="7" md="12" sm="12">
                    <p class="mb-2 font-weight-bold text-left">Models</p>
                    <b-row class="mb-3">
                        <b-col sm="12" class="text-left">
                            <label class="text-left w-fit-content">Maintenance:</label>
                            <b-form-checkbox v-model="showMaintenance" name="check-button" switch size="lg" class="ml-2"
                                :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <b-col sm="12" class="text-left">
                            <label class="text-left w-fit-content">Safety:</label>
                            <b-form-checkbox v-model="showSafety" name="check-button" switch size="lg" class="ml-2"
                                :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <hr class="my-0" />
            <b-row class="my-2">
                <b-col lg="7" md="12" sm="12">
                    <p class="mb-2 font-weight-bold text-left">Doc Type</p>
                    <b-row class="mb-3">
                        <b-col sm="12" class="text-left">
                            <label class="text-left w-fit-content">Doc Type Required:</label>
                            <b-form-checkbox v-model="docTypeRequired" name="check-button" switch size="lg" class="ml-2"
                                :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                    $store.state.user.user.team_member.role !== 'Operations'"></b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div class="buttons text-left mt-3 d-flex align-items-center">
                <b-button to="/" class="mx-0 h-40px w-100px" variant="secondary">Close</b-button>
                <b-button @click="updateCompanyData" btn class="save mx-0 mb-0 h-40px w-100px  ml-3" variant="primary" v-if="$store.state.user.user.team_member.role === 'Admin' ||
                    $store.state.user.user.team_member.role === 'Operations'">Save</b-button>
            </div>
        </b-form>
        <qriously class="d-none" v-if="$store.state.user.user.team_member.role === 'Admin' ||
            $store.state.user.user.team_member.role === 'Operations'"
            :value="'https://app.digitalpermitbook.com/company/' + company[0].path" :size="200" ref="qrcode" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import generateQRCode from "../../services/generateCompanyQRCode.js";

export default {
    name: "Settings",
    data() {
        return {
            qrCodeUrl: "",
            showMaintenance: false,
            showFileDrop: false,
            showSafety: false,
            docTypeRequired: false
        }
    },
    methods: {
        ...mapActions(["updateCompany", 'setNotification', "getAllCountries", "setLoader"]),
        generateCompanyQRCode(fileName, qrCode) {
            generateQRCode(this.company[0].name, fileName, qrCode)
        },
        async copyURL(url) {
            if (url) {
                try {
                    await navigator.clipboard.writeText(url);
                    await this.setNotification({
                        msg: `Company Path Copied Successfully.`,
                        type: "success",
                        color: "green",
                    });
                } catch (e) {
                    console.log(e)
                }
            }
        },
        payloadToFormData(payload) {
            let formData = new FormData();
            for (let [key, value] of Object.entries(payload)) {
                if (key === "logo" && this.companyLogo) {
                    formData.append(key, this.companyLogo, this.companyLogo.name);
                } else if (key !== "logo") {
                    if (value !== null) {
                        formData.append(key, value);
                    }
                }
            }
            return formData;
        },
        async updateCompanyData() {
            try {
                this.setLoader(true)
                let companyData = {
                    ...this.company[0],
                    state: this.company[0].state.id,
                    show_maintenance: this.showMaintenance,
                    show_file_drop: this.showFileDrop,
                    show_safety: this.showSafety,
                    doc_type: this.docTypeRequired,
                    company_id: this.userCompany
                };
                const formData = this.payloadToFormData(companyData);
                const updatedCompany = await this.updateCompany({
                    id: `${this.userCompany}`,
                    payload: formData,
                });
                if (updatedCompany) {
                    this.company[0] = updatedCompany;
                    await this.setNotification({
                        msg: `Successfully updated ${this.company[0].name}`,
                        type: "success",
                        color: "green",
                    });
                }
                this.setLoader(false)
            } catch (error) {
                this.setLoader(false)
                console.log(error);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        }
    },
    computed: {
        ...mapGetters(['company', "userCompany"]),
        companyPath() {
            return this.company[0].path ? `${process.env.VUE_APP_APP_URL}company/${this.company[0].path}` : ''
        }
    },
    watch: {
        company: {
            async handler() {
                await this.$nextTick()
                this.showMaintenance = this.company[0].show_maintenance
                this.showFileDrop = this.company[0].show_file_drop
                this.showSafety = this.company[0].show_safety
                this.docTypeRequired = this.company[0].doc_type
                if (this.$store.state.user.user.team_member.role === 'Admin' ||
                    this.$store.state.user.user.team_member.role === 'Operations')
                    this.qrCodeUrl =
                        this.$refs?.qrcode.qrious._canvasRenderer.element.toDataURL();
            },
            immediate: true,
        }
    }
}
</script>