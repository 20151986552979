<template>
    <div>
        <b-modal id="modal-smart-drop-plus-preview" centered size="lg" no-close-on-backdrop
            :body-class="'px-3 pt-2 overflow-preview-auto'" no-close-on-esc :footer-class="'d-block border-0 pt-0 pb-3 m-0'"
            :header-class="'align-items-center'" scrollable>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Document
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
                <b-dropdown id="dropdown-preview" class="load-dropdown mt-0">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button class="mb-2" @click="downloadFile(mediaID)">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Download</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <p class="text_secondary cursor-pointer mb-0 f-14" v-if="documentItem"
                            @click="showDeletePopup(documentItem.pk)">Delete</p>
                    </b-dropdown-item-button>
                </b-dropdown>
            </template>
            <div class="d-block text-center">
                <b-row>
                    <b-col lg="4" sm="12" class="text-left mb-2">
                        <b-row>
                            <b-col sm="12" class="text-left mb-2">
                                <label class="state">Name:</label>
                                <v-select class="bg_white w-100 f-12" v-model="documentData.name" :options="nameOptions"
                                    item-value="id" item-text="label" :reduce="(option) => option.id" @search="searchAssets"
                                    @option:selected="getNameItem($event)">
                                    <span slot="no-options">
                                        Search Unit No
                                    </span>
                                </v-select>
                                <p v-if="errors && errors.name" class="field-error">
                                    {{ errors.name[0] }}
                                </p>
                            </b-col>
                            <b-col sm="12">
                                <div class="text-left mb-2">
                                    <label for="modal-smart-drop-plus-datepicker" class="state">Date:</label>
                                    <b-form-datepicker id="modal-smart-drop-plus-datepicker" v-model="documentData.date"
                                        reset-button></b-form-datepicker>

                                </div>
                            </b-col>
                            <b-col sm="12">
                                <div class="text-left mb-2">
                                    <label for="modal-smart-drop-plus-expiry-date" class="state">Expiry Date:</label>
                                    <b-form-datepicker id="modal-smart-drop-plus-expiry-date"
                                        v-model="documentData.expiry_date" reset-button></b-form-datepicker>
                                </div>
                            </b-col>
                            <b-col sm="12">
                                <div class="text-left mb-2">
                                    <label class="state">Doc Type:</label>
                                    <b-form-select class="f-12" :options="getDotOptions" v-model="documentData.doc_type"
                                        @change="setDocDescription($event)">
                                    </b-form-select>
                                    <p v-if="errors && errors.doc_type" class="field-error">
                                        {{ errors.doc_type[0] }}
                                    </p>
                                </div>
                            </b-col>
                            <b-col sm="12">
                                <div class="text-left mb-2">
                                    <label for="modal-smart-drop-plus-expiry-date" class="state">Description:</label>
                                    <b-form-input type="text" placeholder="Description" v-model="documentData.description"
                                        class="unit f-12"></b-form-input>
                                </div>
                            </b-col>
                            <b-col sm="12" v-if="documentItem && documentItem.source === 'Driver' && documentItem.rejected">
                                <div class="text-left mb-2">
                                    <label for="modal-smart-drop-plus-expiry-date" class="state">Rejected Reason:</label>
                                    <b-form-input type="text" placeholder="Rejected Reason"
                                        v-model="documentItem.rejection_reason" class="unit f-12" disabled></b-form-input>
                                </div>
                            </b-col>
                            <b-col sm="12">
                                <div class="text-left mb-2">
                                    <label class="state">Driver</label>
                                    <b-form-checkbox v-model="documentData.driver" name="check-button" switch size="lg"
                                        class="ml-0-6rem" />
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col lg="8" sm="12" class="text-left mb-2 position-relative">
                        <div class="review-media-section">
                            <div id="panzoom-element" ref="panzoomElement">
                                <vue-pdf-embed v-if="mediaType === 'pdf'" :source="media" />
                                <img :src="media" v-else class="w-100 h-100 img-section" />
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center mt-2 zoom-section">
                            <b-icon @click="zoom(1)" icon="zoom-in" style="fill:white; cursor: pointer;"></b-icon>
                            <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out"
                                style="fill:white;cursor: pointer;"></b-icon>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <div class="d-flex modal-footer border-0 p-0 mt-3"
                    :class="documentItem && (documentItem.source === 'Driver' && !documentItem.rejected) ? 'justify-content-between' : 'justify-content-end'">
                    <div class="d-flex m-0"
                        v-if="documentItem && (documentItem.source === 'Driver' && !documentItem.rejected)">
                        <b-button class="cancel text-white mr-3" @click="$bvModal.show('modal-reject-doc')"
                            variant="danger">Reject</b-button>
                    </div>
                    <div class="d-flex m-0">
                        <b-button class="btn cancel mr-3" block @click="resetPopup">Close</b-button>
                        <b-button class="btn save mx-0" variant="primary" @click="updateDocument">Approve</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
        <RejectDoc :documentItem="documentItem" :documentData="documentData"
            @docRejected="resetPopup(); $emit('docsUpdated')" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as moment from "moment-timezone";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import RejectDoc from './RejectDoc.vue';
import Panzoom from '@panzoom/panzoom'
export default {
    components: { vSelect, VuePdfEmbed, RejectDoc },
    props: ['documentItem', 'company', 'showDeletePopup'],
    computed: {
        ...mapGetters(["docTypeItems"]),
        getDotOptions() {
            return [...[{
                value: "",
                text: "",
            }], ...this.docTypeItems?.filter((item) => item.model === this.modelType || item.model === 'All').map((item) => {
                return {
                    value: item.id,
                    text: item.type,
                }
            })]
        },
    },
    data() {
        return {
            nameOptions: [],
            modelType: null,
            panzoom: null,
            documentData: {
                name: "",
                date: "",
                expiry_date: "",
                description: "",
                driver: false,
                doc_type: "",
            },
            media: "",
            mediaID: "",
            mediaType: "",
            errors: {}
        }
    },
    name: "SmartDropPreview",
    watch: {
        documentItem() {
            if (this.documentItem) {
                console.log(this.documentItem)
                this.documentData.description = this.documentItem.description
                this.documentData.date = this.documentItem.date ? moment(this.documentItem.date).format("YYYY-MM-DD") : ''
                this.documentData.expiry_date = this.documentItem.expiry
                this.documentData.driver = this.documentItem.driver
                this.documentData.doc_type = this.documentItem.doc_type
                this.modelType = this.documentItem.type
                if (this.documentItem.name !== '-') {
                    this.documentData.name = this.documentItem.userID
                    this.nameOptions.push({
                        id: this.documentItem.userID,
                        label: this.documentItem.name,
                    })
                }
                this.media = this.documentItem.link
                this.mediaID = this.documentItem.pk
                if (this.documentItem.link.includes('.pdf')) this.mediaType = 'pdf'
                else this.mediaType = 'image'
            }
        }
    },
    methods: {
        ...mapActions(["getAllEquipments", "setLoader", "setNotification", "updateTeamMemberDocument", "retrieveDocument"]),
        zoom(level) {
            if (!this.panzoom) {
                this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
                    maxScale: 5
                })
            }
            level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
        },
        getNameItem(option) {
            this.modelType = option.type
        },
        setDocDescription(event) {
            if (event) {
                this.documentData.description = this.getDotOptions.filter((option) => option.value === event)[0].text
            }
            else this.documentData.description = ""
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async downloadFile(id) {
            this.setLoader(true)
            const doc = await this.retrieveDocument(id);
            fetch(doc.document)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const newUrl = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = newUrl;
                    const parts = doc.document.split('/');
                    a.download = parts.pop().split('?')[0];
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(newUrl);
                    a.remove()
                    this.setLoader(false)
                })
                .catch(() => this.setLoader(false));
        },
        resetPopup() {
            this.nameOptions = [],
                this.documentData = {
                    name: "",
                    date: "",
                    expiry_date: "",
                    description: "",
                    driver: false,
                    doc_type: ""
                },
                this.errors = {}
            this.panzoom = null
            this.media = ""
            this.mediaID = ""
            this.nameOptions = []
            this.mediaType = ""
            this.$bvModal.hide('modal-smart-drop-plus-preview')
            this.$emit("close")
        },
        async searchAssets(searchText) {
            if (searchText) {
                let options = []
                let url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
                const equipmentRecords = await this.getAllEquipments(url);
                equipmentRecords.records.map((item) => {
                    options.push({
                        id: `${item.id}`,
                        label: item.unit_no,
                        type: item.type
                    });
                });
                this.nameOptions = options
            }
        },
        async updateDocument() {
            try {
                const nullKeys = this.checkNullValues({
                    name: this.documentData.name,
                    ...this.company[0].doc_type ? { doc_type: this.documentData.doc_type } : {}
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    delete this.documentData.document;
                    let formData = new FormData();
                    formData.append("object_id", this.documentData.name);
                    formData.append(
                        "date",
                        moment(this.documentData.date).format("YYYY-MM-DD")
                    );
                    formData.append("description", this.documentData.description);
                    formData.append("doc_type", this.documentData.doc_type);
                    formData.append("type", this.documentItem.type);
                    formData.append("expiry_date", this.documentData.expiry_date ? this.documentData.expiry_date : '');
                    formData.append("driver", this.documentData.driver);
                    formData.append(
                        "model_type", "equipment"
                    )
                    formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
                    if (this.documentItem.source === 'BOT') formData.append("data_extraction_successful", true);
                    else formData.append("rejected", false)
                    await this.updateTeamMemberDocument({
                        id: this.documentItem.pk,
                        payload: formData,
                    });
                    await this.setNotification({
                        msg: "Document Successfully updated",
                        type: "success",
                        color: "green",
                    });
                    this.resetPopup()
                    this.setLoader(false)
                    this.$emit('docsUpdated')
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                }
                console.log(this.errors)
            }
        }
    },
}
</script>
<style>
.review-media-section {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: auto !important;
    left: 0;
    right: 15px;
    margin-top: 28px;
}

.overflow-preview-auto {
    overflow: auto !important;
    padding-bottom: 1rem !important;
}

.img-section {
    object-fit: contain;
    max-width: -webkit-fill-available;
    max-height: -webkit-fill-available;
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    object-position: top;
}

@media (max-width: 768px) {
    .overflow-preview-auto {
        overflow: auto !important;
    }

    .review-media-section {
        position: relative;
        top: auto;
        bottom: auto;
        overflow: auto;
        left: auto;
        right: auto;
        margin-top: 0px
    }
}
</style>
