<template>
    <div class="bg-white rounded">
        <div class="table pt-0 mb-0">
            <div class="add_license d-flex justify-content-between align-items-center flex-wrap mb-3 px-3">
                <h3 class="font-weight-bold f-18 text-left mb-0 text-black">Activity</h3>
            </div>
            <b-table show-empty responsive :fields="fields" :items="items" hover :current-page="currentPage" :fixed="true">
                <template #cell(remarks)="data">
                    <p class="text-pre-line mb-0">{{ data.value }}</p>
                </template>
            </b-table>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import * as moment from "moment-timezone";
export default {
    name: "ActivityLogs",
    props: ['record', 'object_id'],
    data() {
        return {
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            fields: [
                {
                    key: "action_time",
                    label: "Date/Time",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                    stickyColumn: true,
                },
                {
                    key: "actor_name",
                    label: "User",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "remarks",
                    label: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            items: [],
            isBusy: false,
            currentPage: 1,
            sortBy: undefined,
            sortDesc: undefined,
            emptyTable: false,
        }
    },
    watch: {
        sortBy: {
            async handler() {
                await this.getLogsData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getLogsData();
            },
        },
    },
    methods: {
        ...mapActions(['getModelActivityLogs']),
        async getLogsData() {
            try {
                this.items = []
                this.isBusy = true
                let url = ""
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=actor_name&sort_order=asc&`
                }
                url += `record=${this.record}&object_id=${this.object_id}`
                const data = await this.getModelActivityLogs(url);
                data.map((item) => {
                    this.items.push({
                        action_time: item.action_time ? moment.tz(item.action_time, this.userTimeZone).format("MMM DD, YYYY HH:mm") : '-',
                        actor_name: item.actor_name ? item.actor_name : '-',
                        remarks: item.remarks ? item.remarks : '-'
                    })
                })
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        }
    },
    mounted() {
        this.getLogsData()
    },
}
</script>
<style>
.activity-log-search-field {
    width: 335px;
}

.activity-log-search-field>div {
    padding: 10px 0px !important;
}
</style>