<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" title="Edit Recurring Schedule" backdrop shadow
            :visible="!!recordID" :right="true" no-header-close no-close-on-backdrop no-close-on-esc>
            <div class="d-block text-center mt-3">
                <b-row class="align-items-center px-4">
                    <b-col sm="12">
                        <div class="text-left">
                            <b-form-group label="Description" class="text-left">
                                <b-form-input type="text" placeholder="Enter Description" required
                                    v-model="recurringSchedulePayload.description" disabled></b-form-input>

                            </b-form-group>
                        </div>
                    </b-col>
                    <b-col sm="12">
                        <div class="text-left select_row">
                            <label class="state">Interval</label>
                            <b-form-select class="o-1" v-model="recurringSchedulePayload.interval_type" disabled :options="[
                                {
                                    value: '',
                                    text: ''
                                },
                                {
                                    value: '2',
                                    text: 'Miles'
                                },
                                {
                                    value: '1',
                                    text: 'Days'
                                },
                            ]" />

                        </div>
                    </b-col>
                    <b-col sm="12" v-if="recurringSchedulePayload.interval_type">
                        <div class="text-left mt-3">
                            <b-form-group
                                :label="recurringSchedulePayload.interval_type === '2' ? 'Miles Interval (mi)' : 'Days Interval (days)'"
                                class="text-left">
                                <b-form-input type="number"
                                    :placeholder="recurringSchedulePayload.interval_type === '2' ? 'Enter Miles Interval (mi)' : 'Enter Days Interval (days)'"
                                    required v-model="recurringSchedulePayload.interval" disabled></b-form-input>

                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
                <div>
                    <b-row class="align-items-center px-4">
                        <b-col sm="12">
                            <div class="text-left select_arrow ">
                                <label class="state">Tags</label>
                                <v-select class="bg_white w-100" multiple v-model="recurringSchedulePayload.tags"
                                    :options="allTags" item-value="id" item-text="label" :reduce="(option) => option.id">

                                </v-select>
                                <p v-if="errors && errors.unit_no" class="field-error">
                                    {{ errors.unit_no[0] }}
                                </p>
                            </div>
                        </b-col>
                        <b-col sm="12">
                            <div class="text-left select_arrow mt-3">
                                <label class="state">Units</label>
                                <v-select class="bg_white w-100 h-100px" multiple v-model="availableUnits"
                                    :options="equipOptions" item-value="id" item-text="label"
                                    :reduce="(option) => option.id" @search="searchAssets" @option:deselected="deleteUnit">
                                    <span slot="no-options">
                                        Search Unit No or Name...
                                    </span>
                                </v-select>
                            </div>
                            <hr class="my-4" />
                        </b-col>
                        <b-col sm="12">
                            <h3 class="f-18 font-weight-bold text-left mb-3">Units ({{ availableUnits.length }})</h3>
                        </b-col>
                    </b-row>
                    <div class="recurring-schedule-table mb-7rem">
                        <b-table responsive :fields="fields" :items="items" hover :current-page="currentPage" show-empty>
                            <template #cell(next_service)="data">
                                <b-form-input v-if="recurringSchedulePayload.interval_type === '2'"
                                    v-model="data.item.next_service" class="w-50" />
                                <b-form-input type="date" v-else v-model="data.item.date" class="w-50" />
                            </template>
                            <template #cell(action)="data">
                                <svg @click="deleteTableItem(data.item.id)" class="cursor-pointer" width="19"
                                        height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
            <div class="sidebar-footer position-fixed">
                <div class="d-flex align-items-center justify-content-end">
                    <div class="text-right modal-footer border-0 p-0">
                        <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="resetPopup()"
                            variant="secondary">Close</b-button>
                        <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary"
                            @click="updateRecurringSchedule()">Save</b-button>
                    </div>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as moment from "moment-timezone";
export default {
    name: "EditRecurringSchedule",
    components: { vSelect },
    props: ['recordID'],
    data() {
        return {
            recurringSchedulePayload: {
                description: "",
                interval_type: "",
                interval: "",
                tags: []
            },
            errors: {},
            items: [],
            currentPage: 1,
            availableUnits: [],
            equipOptions: []
        }
    },
    computed: {
        ...mapGetters([
            "userCompany",
            "tags"
        ]),
        fields() {
            if (this.recurringSchedulePayload.interval_type === '2') {
                return [
                    {
                        label: "Unit No",
                        key: "unit_no",
                        sortable: true,
                        thClass: 'font-table-weight-bold'
                    },
                    {
                        label: "Current Mi",
                        key: "current_mi",
                        sortable: true,
                        thClass: 'font-table-weight-bold'
                    },
                    {
                        key: "next_service",
                        label: "Next Service",
                        sortable: true,
                        thClass: 'font-table-weight-bold'
                    },
                    {
                        key: "Action",
                        sortable: true,
                        thClass: 'font-table-weight-bold'
                    },
                ]
            }
            return [
                {
                    label: "Unit No",
                    key: "unit_no",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "next_service",
                    label: "Next Service",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ]
        },
        allTags() {
            return this.tags.map((item) => {
                return {
                    id: item.id,
                    label: item.add_tag,
                }
            })
        }
    },
    methods: {
        ...mapActions(['getRecurringScheduleRecord', 'getAllTags', "getAllEquipments", "setLoader", "setNotification", "updateRecurringScheduleRecord"]),
        deleteUnit(option) {
            this.items = this.items.filter((item) => item.id !== option.id)
        },
        deleteTableItem(id) {
            this.availableUnits = this.availableUnits.filter((item) => item !== id)
            this.items = this.items.filter((item) => item.id !== id)
        },
        resetPopup() {
            this.recurringSchedulePayload = {
                description: "",
                interval_type: "",
                interval: "",
                tags: []
            }
            this.errors = {}
            this.items = []
            this.equipOptions = [],
                this.availableUnits = [],
                this.$emit('resetEditRecord');
        },
        async searchAssets(searchText) {
            if (searchText) {
                let options = []
                let url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
                try {
                    const equipmentRecords = await this.getAllEquipments(url);
                    equipmentRecords.records.map((item) => {
                        options.push({
                            id: item.id,
                            label: item.unit_no,
                            odometer: item.current_odometer ? item.current_odometer : 0
                        });
                    });
                    this.equipOptions = [...options, ...this.equipOptions]
                    this.equipOptions = [...new Map(this.equipOptions.map(item => [item['id'], item])).values()]
                }
                catch (e) {
                    console.log(e)
                }
            }
        },
        async updateRecurringSchedule() {
            if (this.availableUnits.length) {
                try {
                    this.setLoader(true)
                    const payload = {
                        description: this.recurringSchedulePayload.description,
                        interval_type: this.recurringSchedulePayload.interval_type,
                        company: this.userCompany,
                        units: this.items.map((item) => {
                            return {
                                "unit_no": item.id,
                                ...this.recurringSchedulePayload.interval_type === '2' ? { "next_service_mile": item.next_service } : { "next_service_date": moment(item.date).format("YYYY-MM-DD") }
                            }
                        }),
                        ...this.recurringSchedulePayload.interval_type === '2' ? { interval_miles: this.recurringSchedulePayload.interval } : { interval_days: this.recurringSchedulePayload.interval }
                    }
                    await this.updateRecurringScheduleRecord({ id: this.recordID, payload })
                    await this.setNotification({
                        msg: "Recurring Schedule Data Added Successfully.",
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$emit("recordUpdated")
                    this.resetPopup()
                }
                catch (e) {
                    console.log(e)
                    this.setLoader(false)
                }
            }
            else {
                await this.setNotification({
                    msg: "Units are required. ",
                    type: "error",
                    color: "red",
                });
            }
        }
    },
    watch: {
        async recordID() {
            if (this.recordID) {
                try {
                    const data = await this.getRecurringScheduleRecord(this.recordID)
                    this.recurringSchedulePayload = {
                        description: data.description,
                        interval_type: data.interval_type,
                        interval: data.interval,
                        tags: []
                    }
                    console.log(data)
                    this.equipOptions = data.recurring_schedule_group.map((item) => {
                        return {
                            id: item.unit_no,
                            label: item.unit_name,
                            odometer: item.current_odometer ? item.current_odometer : 0,
                            next_service_miles: item.next_service_miles,
                            next_service_date: item.next_service_date
                        }
                    })
                    this.availableUnits = data.recurring_schedule_group.map((item) => item.unit_no)
                }
                catch (e) {
                    console.log(e)
                }
            }
        },
        availableUnits: {
            handler() {
                // this.items = []
                if (this.availableUnits.length) {
                    const data = this.availableUnits.map(id => {
                        const foundItem = this.equipOptions.find(item => item.id === id);
                        if (foundItem) {
                            return foundItem
                        }
                    });
                    data.map((item) => {
                        if (this.items.filter((data) => data.id === item.id).length === 0) {
                            this.items.push({
                                unit_no: item.label,
                                id: item.id,
                                current_mi: item.odometer.toLocaleString(),
                                next_service: item.next_service_miles ? item.next_service_miles : this.recurringSchedulePayload.interval_type === '2' ? Number(item.odometer) + Number(this.recurringSchedulePayload.interval) : moment().add(this.recurringSchedulePayload.interval, 'days').format("MMM DD, YYYY"),
                                date: item.next_service_date ? item.next_service_date : moment().add(this.recurringSchedulePayload.interval, 'days').format("YYYY-MM-DD")
                            })
                        }
                    })
                } else this.items = []
            },
            deep: true
        },
        recurringSchedulePayload: {
            async handler() {
                if (this.recurringSchedulePayload.tags.length) {
                    let options = []
                    const equipTags = this.recurringSchedulePayload.tags.map(id => {
                        const foundItem = this.tags.find(item => item.id === id);
                        if (foundItem) {
                            return foundItem.add_tag
                        }
                    });
                    let url = `tags=${equipTags.toString()}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
                    try {
                        this.setLoader(true)
                        const equipmentRecords = await this.getAllEquipments(url);
                        equipmentRecords.records.map((item) => {
                            options.push({
                                id: `${item.id}`,
                                label: item.unit_no,
                                odometer: item.current_odometer ? item.current_odometer : 0,
                                type: "equipment",
                            });
                        });
                        this.equipOptions = [...options, ...this.equipOptions]
                        this.equipOptions = [...new Map(this.equipOptions.map(item => [item['id'], item])).values()]
                        this.availableUnits = this.equipOptions.map((item) => item.id)
                        this.setLoader(false)
                    }
                    catch (e) {
                        this.setLoader(false)
                    }
                }
            },
            deep: true,
            immediate: false
        }
    }
}
</script>