<template>
    <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 team-page">
        <b-row>
            <b-col cols="" class="">
                <b-row>
                    <b-col cols="">
                        <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
                            <h2 class="text-left">Required Documents</h2>
                            <b-dropdown id="dropdown-divider" text="text" class="load-dropdown-2">
                                <template slot="button-content">
                                    <b-icon class="dots" icon="three-dots"></b-icon>
                                </template>
                                <b-dropdown-item-button @click="downloadExcel">
                                    <p class="text_secondary cursor-pointer mb-0 f-14">Export CSV</p>
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <div class="data-table team-data-table pt-3 pt-sm-4 bg-white rounded table-custom-border">
                    <div class="table_row team_table_row">
                        <div class="equipment-border filter-box">
                            <div class="d-flex mb-0 px-3 px-sm-4">
                                <b-form-group class="text-left label font-weight-normal search-field mb-0">
                                    <b-input-group class="username-input-field">
                                        <b-form-input type="text" placeholder="Search" v-model="filterFields.search"
                                            class="unit f-12" @keyup.enter="getTableData()"
                                            :disabled="isBusy"></b-form-input>
                                        <b-input-group-append>
                                            <b-button variant="primary" @click="getTableData()" :disabled="isBusy">
                                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group label="" class="text-left select_arrow label w-equipment-box">
                                    <b-form-select v-model="filterFields.required" class="f-12" :disabled="isBusy"
                                        @change="setDocTypeData($event)" :options="[
                                            {
                                                value: '',
                                                text: 'Select Required'
                                            },
                                            {
                                                value: 'True',
                                                text: 'Yes'
                                            },
                                            {
                                                value: 'False',
                                                text: 'No'
                                            }
                                        ]" />
                                </b-form-group>
                                <b-form-group label="" class="text-left select_arrow label w-equipment-box">
                                    <b-form-select v-model="filterFields.type" :disabled="isBusy" class="f-12"
                                        @change="setDocTypeData($event)" :options="[
                                            {
                                                value: '',
                                                text: 'Select Type'
                                            },
                                            {
                                                value: 'Driver',
                                                text: 'Driver'
                                            },
                                            {
                                                value: 'Vehicle',
                                                text: 'Vehicle'
                                            },
                                            {
                                                value: 'Trailer',
                                                text: 'Trailer'
                                            }
                                        ]" />
                                </b-form-group>
                                <b-form-group label="" class="text-left select_arrow label w-equipment-box">
                                    <b-form-select v-model="filterFields.doc_type" class="f-12" @change="getTableData()"
                                        :disabled="docTypeOptions.length === 0 || isBusy" :options="docTypeOptions.length > 0 ? docTypeOptions : [{
                                            value: '',
                                            text: 'Select Doc Type'
                                        }]" />
                                </b-form-group>
                                <b-form-group class="text-left w-equipment-box mb-3">
                                    <v-select multiple :disabled="isBusy" :options="allTags" class="f-12"
                                        :reduce="(option) => option.id" v-model="filterFields.tags"
                                        placeholder="Select Tags" @input="getTableData()" />
                                </b-form-group>
                            </div>
                        </div>
                        <b-table class="mt-2" responsive :fields="fields" :items="items" hover :current-page="currentPage"
                            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
                            :show-empty="emptyTable">
                            <template #cell(missing_doc_type_ids)="data">
                                <div>
                                    {{ data.value.length ? getDocTypeID(data.value) : '-' }}
                                </div>
                            </template>
                            <template #cell(action)="data">
                                <svg @click="addRequiredDoc(data.item)" class="cursor-pointer" width="20" height="20"
                                    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#2D69F6" />
                                    <path
                                        d="M10 13.3334V10.0001V13.3334ZM10 10.0001V6.66675V10.0001ZM10 10.0001H13.3334H10ZM10 10.0001H6.66669H10Z"
                                        fill="#2D69F6" />
                                    <path d="M10 13.3334V10.0001M10 10.0001V6.66675M10 10.0001H13.3334M10 10.0001H6.66669"
                                        stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                </svg>
                            </template>
                        </b-table>
                        <div class="entries-pagination px-3 px-sm-4">
                            <div class="d-flex align-items-center flex-wrap">
                                <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                                    <b-form-select v-model="perPage" :options="pageOptions" />
                                </div>
                                <div class="pagination">
                                    <b-pagination v-model="currentPage" :total-rows="rows"
                                        :per-page="perPage"></b-pagination>
                                </div>
                                <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <AddDoc :docData="docData" @close="docData = null" @onAddDoc="getTableData()"/>
    </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css"
import AddDoc from './AddDoc.vue';
export default {
    name: "RequiredDocs",
    components: { vSelect, AddDoc },
    data() {
        return {
            rows: 1,
            allTags: [],
            docData: null,
            fields: [
                {
                    label: "Name",
                    key: "name",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Type",
                    key: "type",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Doc Type",
                    key: "missing_doc_type_ids",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Action",
                    key: "action",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
            ],
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            items: [],
            sortBy: undefined,
            emptyTable: false,
            sortDesc: undefined,
            isBusy: false,
            perPage: 10,
            currentPage: 1,
            positionText: "",
            docTypeOptions: [],
            filterFields: {
                search: '',
                date: '',
                type: '',
                doc_type: '',
                tags: [],
                status: '',
                required: "True"
            },
        }
    },
    computed: {
        ...mapGetters(["company", 'tags', 'docTypeItems', 'userCompany'])
    },
    methods: {
        ...mapActions(['getAllTags', 'getAllRequiredDocs', 'setLoader', 'setNotification', 'exportAllRequiredDocsData']),
        addRequiredDoc(data) {
            this.docData = data
            this.$bvModal.show('modal-document')
        },
        setDocTypeData() {
            if (this.filterFields.type) {
                this.docTypeOptions = [...[{
                    value: "",
                    text: "Select Doc Type",
                }], ...this.docTypeItems?.filter((item) => item.model === this.filterFields.type && (!this.filterFields.required || this.filterFields.required === "True" && item.required || this.filterFields.required === "False" && !item.required)).map((item) => {
                    return {
                        value: item.id,
                        text: item.type,
                    }
                })]
            }
            else this.docTypeOptions = []
            this.getTableData()
        },
        async downloadExcel() {
            try {
                this.setLoader(true)
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=name&sort_order=asc&`
                }
                url += 'export_to_csv=True'
                const data = await this.exportAllRequiredDocsData(url)
                const blob = new Blob([data], { type: 'text/csv' });
                const csvUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = csvUrl;
                a.download = 'required-docs.csv';
                a.target = "_blank"
                document.body.appendChild(a);
                a.click();
                a.remove()
                window.URL.revokeObjectURL(url);
                await this.setNotification({
                    msg: `File Exported Successfully`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        },
        getDocTypeID(data) {
            const docTypItems = data.map(id => {
                const foundItem = this.docTypeItems.find(item => item.id === id);
                if (foundItem) {
                    return foundItem.type
                }
            });
            return docTypItems.join(", ")
        },
        async getTableData() {
            try {
                this.isBusy = true
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=name&sort_order=asc&`
                }
                url += `page=${this.currentPage}&page_size=${this.perPage}`
                const data = await this.getAllRequiredDocs(url);
                if (data.total_entries) {
                    this.rows = data.total_entries
                    this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
                }
                else {
                    this.rows = 0
                    this.positionText = `0 to 0 of 0`
                }
                this.items = []
                data.records.map((item) => {
                    const tableItem = {}
                    Object.keys(item).map((key) => {
                        if (item[key]) tableItem[key] = item[key]
                        else tableItem[key] = "-"
                    })
                    this.items.push(tableItem)
                })

                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        },
    },
    watch: {
        perPage: {
            async handler() {
                await this.getTableData();
            },
            immediate: true,
        },
        currentPage: {
            async handler() {
                await this.getTableData();
            },
        },
        sortBy: {
            async handler() {
                await this.getTableData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getTableData();
            },
        },
        filterFields: {
            handler() {
                this.currentPage = 1
            },
            deep: true
        },
        company: {
            async handler() {
                await this.getAllTags(this.userCompany);
                this.tags.map((item) => {
                    this.allTags.push({
                        id: item.id,
                        label: item.add_tag,
                    });
                });
            },
            deep: true,
            immediate: true
        }
    }
}
</script>