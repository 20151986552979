import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import { BootstrapVueIcons } from "bootstrap-vue";
import VueQriously from "vue-qriously";
import store from "./store";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";


const globalOptions = {
  mode: "auto",
};

Vue.use(VueTelInput, globalOptions);
Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVue);
Vue.use(VueQriously);
Vue.use(VueMask);
Vue.config.productionTip = false;

Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
