<template>
  <div id="app">
    <b-container fluid>
      <Header />
      <b-row id="wrapper" :class="{ toggled: openSidebar }">
        <Sidebar v-on:sidebarState="getState($event)" />
        <div id="page-content-wrapper" class="table-body">
          <div class="expiry-warning-section mt-2" v-if="!!expiryWarning">
            <div class="expiry-notification bg-white px-2 py-3 mb-3">
              <p class="mb-0 f-14 text-left">This subscription expires on {{ expiryWarning }}. To renew subscription,
                please
                contact support@digitalpermitbook.com
              </p>
            </div>
          </div>
          <router-view v-if="user && company && company[0]" />
        </div>
      </b-row>
    </b-container>
    <AccountExpiry v-if="showAccountExpiry" />
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Sidebar from "@/components/sidebar/Sidebar.vue";
import { mapActions, mapGetters } from "vuex";
import * as moment from "moment-timezone";
import AccountExpiry from "@/components/AccountExpiry"
export default {
  name: "Website",
  components: {
    Header,
    Sidebar,
    AccountExpiry
  },
  data() {
    return {
      openSidebar: false,
      expiryWarning: "",
      showAccountExpiry: false
    };
  },
  computed: {
    ...mapGetters(["user", "licenseInventory", 'company']),
  },
  methods: {
    ...mapActions(["getUserByID", "getLicenseInventory", "getCompany", "getUserCompanies", "getUserCompany", "getAppVersion", "getAllDocTypes"]),
    getState(item) {
      this.openSidebar = item;
    },
    async getAccountExpiryStatus() {
      const currentDate = new Date();
      const expiryDate = new Date(this.company[0].license_expiry_dates);
      const diffDays = parseInt(
        (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
        10
      );
      console.log(diffDays)
      if (diffDays > 0 && diffDays <= 15) {
        this.expiryWarning = moment(this.company[0].license_expiry_dates).format("MMM DD, YYYY")
      }
      else if (diffDays <= 0) {
        this.showAccountExpiry = true
        await this.$nextTick()
        this.$bvModal.show('modal-account-expiry')
      }
    }
  },
  async mounted() {
    try {
      this.getUserCompany()
      if (localStorage.getItem("userSelectedCompany")) {
        await this.getCompany()
        this.getAccountExpiryStatus()
      }
      else {
        this.$router.push("/all-companies")
      }
      await this.getUserByID(),
        await this.getUserCompanies()
      await this.getAppVersion()
      await this.getAllDocTypes()
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.expiry-data .btn .b-icon.bi,
.table_row .btn .b-icon.bi,
.add_license .btn .b-icon.bi {
  font-size: 150% !important;
}

* {
  font-family: poppins;
}

.text-black {
  color: #000 !important;
}

.o-1 {
  opacity: 1 !important;
}

.h-40px {
  height: 40px;
}

.h-350px {
  height: 350px;
}

.btn-warning {
  background-color: #FFC800 !important;
  border-color: #FFC800 !important;
  color: white !important;
}

body.modal-open {
  overflow: visible;
}

.main-container {
  padding: 0 32px;
}

#wrapper,
.pt-64 {
  padding-top: 64px;
}

.mt-1-8rem {
  margin-top: 1.8rem;
}

.custom-switch {
  padding-left: 0.7rem !important;
}

.pl-3-5 {
  padding-left: 1.2rem !important;
}

.item-description {
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

p.field-error {
  color: red;
  font-size: 13px !important;
  height: 4px;
  margin: 0;
}

.sr-only {
  display: none;
}

/* .table-body {
  background-color: #e5e5e5;
} */
/* body {
    background-color: #e5e5e5 !important;
} */

.expiry-notification {
  border-left: 10px solid #FF0000;
  border-radius: .25rem;
  box-shadow: 0 4px 50px rgb(0 0 0/10%);
}

.expiry-notification>p {
  font-weight: 500;
}

.data-table h4 {
  font-weight: 600;
  color: var(--black);
  font-size: 18px;
}

.label {
  color: var(--black);
  font-size: var(--extrasmall);
}

.label span {
  opacity: 0.6;
}

.expiry-data h2 {
  font-size: 32px;
  font-weight: 700;
  color: var(--black);
  margin-bottom: 0px;
}

.export {
  background-color: #2cce89 !important;
  font-size: 14px !important;
  width: 100px !important;
  height: 40px;
}

.export.table_btn {
  width: 100%;
}

.px-20px {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.col-form-label {
  color: #000000;
  opacity: 0.6;
}

.page-item .page-link {
  height: 29px;
  width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-item.active .page-link {
  background-color: var(--btn-bg-color) !important;
  color: #fff;
}

.page-link {
  background-color: transparent !important;
  margin-right: 10px;
  border-radius: 4px !important;
  border: none !important;
  font-size: 14px !important;
}

.show-entries .custom-select {
  border: none !important;
  font-size: 14px !important;
}

.page-item:not(.active) .page-link {
  color: black !important;
}

.table> :not(caption)>*>* {
  padding: 10px 15px;
}

.table.b-table>thead>tr>[aria-sort="none"] {
  border-bottom: none !important;
}

.table>tbody>tr>td {
  border-bottom: 1px solid #dee2e6;
}

.table.b-table>thead>tr>[aria-sort="none"],
.table.b-table>tfoot>tr>[aria-sort="none"] {
  background-image: url("../assets/up-and-down.svg") !important;
  color: var(--black);
  opacity: 0.6;
}

.table.b-table>thead>tr>[aria-sort="descending"],
.table.b-table>tfoot>tr>[aria-sort="descending"] {
  background-image: url("../assets/up-and-down.svg") !important;
  color: var(--black);
  opacity: 0.6;
}

.table.b-table>thead>tr>[aria-sort="ascending"],
.table.b-table>tfoot>tr>[aria-sort="ascending"] {
  background-image: url("../assets/up-and-down.svg") !important;
  color: var(--black);
  opacity: 0.6;
}

th div {
  font-weight: 400;
  font-size: var(--small);
  text-align: left;
}

tr td {
  text-align: left;
}

.equipment-border fieldset {
  margin-right: 20px;
}

.equipment-border fieldset .col-form-label {
  opacity: 0.6;
  font-weight: 400;
  font-size: var(--small);
}

.left-input .label,
.right-input .label {
  margin-right: 20px;
}

.table_row .v-select {
  border: 1px solid #e3e3e3 !important;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.border-radius-custom {
  border-radius: 0.25rem;
}

.custom-box-shadow,
.data-table {
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%);
}

.table_row .vs--searchable .vs__dropdown-toggle {
  height: 40px;
  border: none !important;
}

.entries-pagination {
  text-align: right;
  background: #fafafa;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
}

.table-responsive {
  margin-bottom: 0 !important;
}

.table td,
.table th {
  vertical-align: middle !important;
}

.entries-pagination p {
  font-size: 14px;
  color: #000000;
  margin-bottom: 19px;
  margin-top: 10px;
}

.show-entries .custom-select {
  background: #fff url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff !important;
  border: 1px solid rgba(94, 114, 228, 0.1);
}

.custom-select {
  background: #f5f5f5 url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat right 0.75rem center/8px 10px !important;
  color: #495057 !important;
}

.show-entries label {
  font-weight: 500;
  margin-bottom: 0;
  margin: 0 10px;
}

.show-entries .custom-select {
  width: 61px;
  height: 29px;
  /* padding-right: 0; */
  padding-top: 4px;
  text-align: center;
  border: 1px solid rgba(94, 114, 228, 0.1);
  border-radius: 5px;
  font-weight: 500;
}

.modal-body {
  padding: 25px !important;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.ml-0-6rem{
  margin-left: 0.6rem;
}
.b-sidebar {
  width: 700px !important;
}

.b-sidebar>.b-sidebar-header {
  background-color: #fff !important;
  color: black !important;
  font-size: 20px !important;
  font-weight: 700;
  padding: 0.8rem 1.5rem !important;
  border-bottom: 1px solid #EAEAEA;
}

.bg-dark {
  background-color: #000 !important;
}

.b-sidebar-backdrop {
  opacity: 0.8 !important;
}

.b-sidebar>.b-sidebar-body {
  background-color: white;
}

#modal-geo-tab-activate .modal-md .modal-content .modal-body,
#modal-isaac-activate .modal-md .modal-content .modal-body,
#modal-ps-activate .modal-md .modal-content .modal-body {
  padding: 25px 25px 0px 25px !important;
}

.dropdown-item:hover {
  background-color: #F6F7F9 !important;
}

.show-entries {
  position: relative;
  padding: 10px 15px 10px 0px !important;
}

.show-entries svg {
  position: absolute;
  right: 65px;
  bottom: 5px;
}

.custom-select:focus {
  border-color: rgba(94, 114, 228, 0.1);
}

.pagination.b-pagination {
  margin-bottom: 0;
}

.pagination .page-item:last-child .page-link {
  margin-right: 0;
}

textarea.form-control::placeholder {
  color: #e3e3e3 !important;
}

input::placeholder {
  color: #e3e3e3 !important;
}

.b-form-btn-label-control.form-control>.form-control {
  color: #212529 !important;
}

#modal-set-password .modal-md,
#send-invite-modal .modal-md,
#tag-modal .modal-md,
#modal-password .modal-md,
#modal-geo-tab-activate .modal-md,
#modal-isaac-activate .modal-md,
#deleteModal .modal-md,
#change-password .modal-md,
#modal-schedule .modal-md,
#modal-schedule-complete .modal-md,
#modal-contact .modal-md,
#modal-driver-public-share .modal-md,
#modal-add-bulk-tags .modal-md,
#modal-ps-activate .modal-md,
#add-type-modal .modal-md,
#modal-bulk-doc-type .modal-md,
#modal-reject-doc .modal-md,
#modal-teammember .modal-md,
#contact-modal .modal-md {
  width: 438px !important
}

#modal-geo-tab-activate .modal-md .modal-content .modal-header,
#modal-isaac-activate .modal-md .modal-content .modal-header,
#modal-ps-activate .modal-md .modal-content .modal-header {
  padding: 0px !important;
}

#help-modal .modal-md,
#modal-account-expiry .modal-md {
  width: 598px !important
}

#modal-violation .modal-md {
  width: 542px !important;
}

.show-password-section .b-icon {
  top: 12px !important
}

.profile_btn .save {
  background-color: var(--btn-bg-color) !important;
  width: 112px;
}

.resize-none {
  resize: none;
}

.sidebar-b-margin,
.mb-7rem {
  margin-bottom: 7rem;
}

.mb-6rem {
  margin-bottom: 6rem;
}

.profile_btn .close,
.profile_btn .close:hover {
  width: 100px;
  height: 40px;
  background: #dddddd !important;
  border: 1px solid #dddddd !important;
  color: #000;
  opacity: 1;
}

.profile_btn .close:active,
.profile_btn .close:focus {
  color: #000;
  background: #dddddd !important;
}

.filter-box>div>fieldset {
  width: 150px !important;
}

.w-fit-content {
  width: fit-content !important;
}

.entries-pagination>div {
  justify-content: space-between;
}

.table thead th {
  opacity: 1 !important;
  background-color: #FAFAFA !important;
}

.font-table-weight-bold>div {
  font-weight: 700 !important;
  opacity: 1
}

.table-custom-border {
  border: 1px solid #dee2e6;
}

.table-custom-border .card-body {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa !important;
}

.text_primary {
  color: var(--btn-bg-color) !important;
}

.cancel_btn {
  font-weight: 600;
}

.text-pre-line {
  white-space: pre-line !important;
}
.zoom-section{
  position: absolute;
    left: 0px;
    right: 15px;
    bottom: 0;
    background: #323639;
    padding: 10px;
}

@media (min-width: 992px) {
  .data-table fieldset:last-child {
    margin-right: 0;
  }

  .modal-lg,
  .modal-xl {
    max-width: 800px !important;
    width: 800px !important;
  }
.mh-470px{
    min-height: 470px !important;
  }
  #modal-smart-drop-plus-preview .modal-lg, #modal-document .modal-lg, #modal-company-docs .modal-lg {
    max-width: 900px !important;
    width: 900px !important;
  }
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background: rgba(250, 250, 250, 0.8) !important
}

.table-hover>tbody>tr:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0.075));
  background-repeat: no-repeat;
  background-color: transparent !important;
}


.table-striped.table-hover>tbody>tr:hover>td,
.table-striped.table-hover>tbody>tr:hover>th {
  --bs-table-accent-bg: #f3f5ff !important;
}

fieldset .col-form-label {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}

.mx-calendar-content .cell {
  text-align: center !important;
}

.change-status-btn {
  width: 110px;
  height: 40px;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.modal-footer {
  border: none !important;
}

.b-sidebar.b-sidebar-right {
  border: 1px solid #EAEAEA;
}

.table-tags {
  background-color: rgba(45, 105, 246, 0.1) !important;
  border: 1px solid rgba(45, 105, 246, 0.5) !important;
  color: #2D69F6 !important;
}

.table-tags {
  border-radius: 4px;
  margin: 4px 2px 0;
  padding: 0 .25em;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .vs__selected .vs__deselect svg > path{
  fill: rgba(45, 105, 246,0.5) !important;
} */
.vs__selected,
.vs__search {
  height: 32px !important;
}

.h-auto>div {
  height: auto !important;
}
.vs__open-indicator, .vs__clear{
  fill: black !important;
  transform: scale(0.6) !important;
}
.h-50px{
  height: 50px !important;
}
.opacity-50{
  opacity: 0.5 !important;
}
@media (max-width: 991px) {
  .data-table {
    overflow-x: scroll;
  }
  .zoom-section{
    left: 15px;
}
}
@media (max-width: 767px) {
  .expiry-data {
    display: block !important;
  }

  .expiry-data h2 {
    font-size: 26px;
  }

  .expiry-data .export {
    width: fit-content;
  }

  #page-content-wrapper {
    padding-left: 76px;
  }

  .main-container {
    padding: 0 10px 0px 0px !important;
  }
}

.expiry-warning-section {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

@media (max-width: 700px) {
  .b-sidebar {
    width: 100% !important;
  }
}

@media (min-width: 575px) {
  .expiry-warning-section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 575px) {
  .entries-pagination>div {
    justify-content: center;
  }

  */ .expiry-data h2 {
    font-size: 20px;
  }

  .export p {
    font-size: 12px;
  }

  .table-body {
    width: 100% !important;
    margin-left: auto;
  }

  #page-content-wrapper {
    padding-left: 0;
  }
}

@media (max-width: 575px) {
  .profile_btn {
    flex-wrap: wrap;
    justify-content: flex-start !important;
  }
}

@media (max-width: 425px) {

  #help-modal .modal-md,
  #modal-set-password .modal-md,
  #send-invite-modal .modal-md,
  #tag-modal .modal-md,
  #modal-password .modal-md,
  #modal-geo-tab-activate .modal-md,
  #modal-isaac-activate .modal-md,
  #deleteModal .modal-md,
  #change-password .modal-md,
  #modal-schedule .modal-md,
  #modal-schedule-complete .modal-md,
  #modal-contact .modal-md,
  #modal-driver-public-share .modal-md,
  #modal-add-bulk-tags .modal-md,
  #modal-ps-activate .modal-md,
  #add-type-modal .modal-md,
  #modal-bulk-doc-type .modal-md,
  #modal-reject-doc .modal-md,
  #modal-teammember .modal-md,
  #contact-modal .modal-md {
    width: 100% !important;

  }
}

@media (max-width: 400px) {
  .b-sidebar>.b-sidebar-header {
    padding: 0.5rem !important;
  }
}</style>
