<template>
    <div>
        <header class="text-left search_equipment d-flex user-detail row">
            <div class="col-md-8 d-flex justify-content-between mx-3 mx-md-auto align-items-center">
                <b-img :src="require('@/assets/form-logo.png')" width="143" height="37"></b-img>
                <div>
                    <b-dropdown id="dropdown-right" right class="mx-2 dropdown dropdown-dark-section header-section-dropdown">
                        <template slot="button-content" v-if="user && user.team_member">
                            <b-img v-if="user.team_member.profile_picture" class="userSmallImage rounded-circle" :src="user.team_member.profile_picture"></b-img>
            <div v-else class="user-default-img">
              {{ user.first_name.charAt(0).toUpperCase() }}{{ user.last_name.charAt(0).toUpperCase() }}
            </div>
                        </template>
                        <b-dropdown-item v-if="user"><span class="font-weight-bold f-14">{{ user.first_name }} {{ user.last_name }}</span>
                            <br /> <span class="f-12">{{ user.email }}</span>
                        </b-dropdown-item>
                        <hr class="my-2" />
                        <b-dropdown-item to="/profile-page"><span class="f-14">View Profile</span></b-dropdown-item>
                        <b-dropdown-item to="/all-companies"><span class="f-14">View Companies</span></b-dropdown-item>
                        <hr class="my-2" />
                        <b-dropdown-item @click="OnlogoutUser"><span class="f-14">Logout</span></b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </header>
        <div class="row">
            <div class="col-md-8 mx-3 mx-md-auto pt-64">
                <h1 class="font-weight-bold f-32 text-left mt-3 mb-3">Companies</h1>
                <div v-for="(company, index) in userCompanies" :key="index">
                    <div class="company-section bg-white mb-3">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center">
                                <div class="companies-icon-section all-section">
                                    {{ company.name.charAt(0).toUpperCase() }}
                                </div>
                                <h3 class="fw-500 mb-0">{{ company.name }}</h3>
                            </div>
                            <b-button variant="primary fw-500" @click="setUserCompany(company.id)">Open</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> 
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "all-companies",
    computed: {
        ...mapGetters(["userCompanies", 'user']),
    },
    methods: {
        ...mapActions(["getUserCompanies", "logoutUser", "getUserByID"]),
        setUserCompany(id) {
            localStorage.setItem('userSelectedCompany', id)
            this.$router.push('/')
        },
        OnlogoutUser() {
            this.logoutUser();
            this.$router.push("/login");
        },
    },
    async mounted() {
        await this.getUserByID();
        this.getUserCompanies()
    }
}
</script>
<style>
.company-section {
    border-radius: 5px;
    padding: 42px 24px;
    border: 1px solid #dee2e6;
}
.company-section > div > div> h3{
    font-size: 25px;
    margin-left: 1.5rem;
}
.company-section>div>button {
    padding: 10px 30px;
    font-size: 14px;
}

#dropdown-right-2 button {
    background-color: transparent !important;
    color: white !important;
    font-weight: 600;
    font-size: var(--small);
    border: unset;
}
.companies-icon-section{
    color: white;
    font-weight: 700;
    background: black;
    min-width: 25px;
    max-width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 5px;
}
.companies-icon-section.all-section{
    min-width: 35px;
    max-width: 35px;
    height: 35px;
}
@media screen  and (max-width: 767px){
    .company-section{
        padding: 16px;
    }
    .company-section > div > div> h3{
    font-size: 14px;
    margin-left: 0.5rem;
}
.company-section>div>button{
    padding: .375rem .75rem;
}
}
</style>