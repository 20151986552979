<template>
    <div class="bg-white rounded">
        <div class="table pt-3 mb-0">
            <div class="add_license d-flex justify-content-between align-items-center flex-wrap mb-3 px-3">
                <h3 class="font-weight-bold f-20 text-left mb-0 text-black">Activity Log</h3>
                <b-form-group class="text-left label font-weight-normal search-field mb-0 activity-log-search-field">
                    <b-input-group class="username-input-field">
                        <b-form-input type="text" placeholder="Search" v-model="filterFields.search_field" class="unit f-12"
                            @keyup.enter="getLogsData()"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="primary" @click="getLogsData()">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <b-table responsive :fields="fields" :items="items" hover :current-page="currentPage"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
                  :show-empty="emptyTable" :fixed="true"
                  >
                  <template #cell(remarks)="data">
                    <p class="text-pre-line mb-0">{{ data.value }}</p>
                </template>
            </b-table>
            <div class="entries-pagination px-3 px-sm-4">
                <div class="d-flex align-items-center flex-wrap">
                    <div class="text-left show-entries ml-md-1 pl-3 pl-md-0">
                        <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">
                        {{ positionText }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import * as moment from "moment-timezone";
export default {
    name: "ActivityLogs",
    data() {
        return {
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            filterFields: {
                search_field: ""
            },
            fields: [
                {
                    key: "action_time",
                    label: "Date/Time",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                    stickyColumn: true,
                },
                {
                    key: "actor_name",
                    label: "User",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "model",
                    label: "Model",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "record",
                    label: "Record",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "remarks",
                    label: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            items: [],
            perPage: 10,
            isBusy: false,
            currentPage: 1,
            sortBy: undefined,
            sortDesc: undefined,
            emptyTable: false,
            positionText: "",
            rows: 0,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
        }
    },
    watch: {
        perPage: {
            async handler() {
                await this.getLogsData();
            },
            immediate: true,
        },
        currentPage: {
            async handler() {
                await this.getLogsData();
            },
        },
        sortBy: {
            async handler() {
                await this.getLogsData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getLogsData();
            },
        },
        filterFields: {
            handler() {
                this.currentPage = 1
            },
            deep: true
        },
    },
    methods: {
        ...mapActions(['getCompanyActivityLogs']),
        async getLogsData() {
            try {
                this.items = []
                this.isBusy = true
                let url = ""
                Object.keys(this.filterFields).map((key) => {
                    if (this.filterFields[key] !== '') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=actor_name&sort_order=asc&`
                }
                url += `page=${this.currentPage}&page_size=${this.perPage}`
                const data = await this.getCompanyActivityLogs(url);
                if (data.total_entries) {
                    this.rows = data.total_entries
                    this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
                }
                else {
                    this.rows = 0
                    this.positionText = `0 to 0 of 0`
                }
                data.records.map((item) => {
                    this.items.push({
                        action_time: item.action_time ? moment.tz(item.action_time, this.userTimeZone).format("MMM DD, YYYY HH:mm") : '-',
                        actor_name: item.actor_name ? item.actor_name : '-',
                        model: item.model ? item.model : '-',
                        record: item.record ? item.record : '-',
                        remarks: item.remarks ? item.remarks : '-'
                    })
                })
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        }
    },
}
</script>
<style>
.activity-log-search-field {
    width: 335px;
}

.activity-log-search-field>div {
    padding: 10px 0px !important;
}
</style>