var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white rounded"},[_c('h3',{staticClass:"font-weight-bold px-3 f-20 pt-4 text-left mb-3 text-black"},[_vm._v("General")]),_c('b-form',{staticClass:"pb-3 px-3"},[_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"lg":"7","md":"12","sm":"12"}},[_c('div',{staticClass:"upload-btn-wrapper mt-2 text-black text-black",class:{ 'h-auto': _vm.companyLogoUrl }},[_c('b-img',{class:{
    img_custom: _vm.companyLogoUrl,
},attrs:{"src":_vm.companyLogoUrl
                        ? _vm.companyLogoUrl
                        : require('@/assets/upload_logo.svg')}}),(!_vm.companyLogoUrl)?_c('button',{staticClass:"foo"},[_vm._v(" Drop your logo here, or browse ")]):_vm._e(),_c('input',{ref:"imageFile",attrs:{"type":"file","name":"imageFile","id":"logoUpload","disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                            _vm.$store.state.user.user.team_member.role !== 'Operations',"accept":"image/*"},on:{"change":_vm.handleImageUpload}})],1),(_vm.$store.state.user.user &&
                    (_vm.$store.state.user.user.team_member.role === 'Admin' ||
                        _vm.$store.state.user.user.team_member.role === 'Operations')
                    )?_c('div',{staticClass:"buttons text-left"},[_c('b-button',{staticClass:"mt-2 cancel",attrs:{"btn":""},on:{"click":_vm.resetImage}},[_vm._v("Reset")]),_c('b-button',{staticClass:"ml-3 mt-2 save",attrs:{"btn":"","variant":"primary"},on:{"click":function($event){return _vm.$refs.imageFile.click()}}},[_vm._v("Upload")])],1):_vm._e()])],1),_c('hr',{staticClass:"my-0"}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"lg":"7","md":"12","sm":"12"}},[_c('b-row',{staticClass:"text-left mb-3"},[_c('b-col',{staticClass:"my-auto",attrs:{"sm":"12"}},[_c('label',[_vm._v("Company Name:")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-input',{attrs:{"disabled":"","placeholder":"Enter Company Name"},model:{value:(_vm.company[0].name),callback:function ($$v) {_vm.$set(_vm.company[0], "name", $$v)},expression:"company[0].name"}}),(_vm.errors && _vm.errors.name)?_c('p',{staticClass:"field-error mb-lg-3"},[_vm._v(" "+_vm._s(_vm.errors.name[0])+" ")]):_vm._e()],1)],1),_c('b-row',{staticClass:"text-left mb-3"},[_c('b-col',{staticClass:"my-auto",attrs:{"sm":"12"}},[_c('label',[_vm._v("Address:")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-input',{attrs:{"disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                            _vm.$store.state.user.user.team_member.role !== 'Operations',"placeholder":"Enter Address"},model:{value:(_vm.company[0].address),callback:function ($$v) {_vm.$set(_vm.company[0], "address", $$v)},expression:"company[0].address"}})],1)],1),_c('b-row',{staticClass:"text-left mb-3"},[_c('b-col',{staticClass:"my-auto",attrs:{"sm":"12"}},[_c('label',[_vm._v("City:")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-input',{attrs:{"disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                            _vm.$store.state.user.user.team_member.role !== 'Operations',"placeholder":"Enter City"},model:{value:(_vm.company[0].city),callback:function ($$v) {_vm.$set(_vm.company[0], "city", $$v)},expression:"company[0].city"}})],1)],1),_c('b-row',{staticClass:"text-left mb-3"},[_c('b-col',{staticClass:"my-auto",attrs:{"sm":"12"}},[_c('label',[_vm._v("State:")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('v-select',{staticClass:"bg_white w-100",attrs:{"disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                            _vm.$store.state.user.user.team_member.role !== 'Operations',"options":_vm.states,"item-value":"id","item-text":"label","reduce":function (option) { return option.id; }},model:{value:(_vm.company[0].state.id),callback:function ($$v) {_vm.$set(_vm.company[0].state, "id", $$v)},expression:"company[0].state.id"}})],1)],1),_c('b-row',{staticClass:"text-left mb-3"},[_c('b-col',{staticClass:"my-auto",attrs:{"sm":"12"}},[_c('label',[_vm._v("Postal Code:")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-input',{attrs:{"disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                            _vm.$store.state.user.user.team_member.role !== 'Operations',"placeholder":"Enter Postal Code"},model:{value:(_vm.company[0].zipcode),callback:function ($$v) {_vm.$set(_vm.company[0], "zipcode", $$v)},expression:"company[0].zipcode"}})],1)],1),_c('b-row',{staticClass:"text-left mb-3"},[_c('b-col',{staticClass:"my-auto",attrs:{"sm":"12"}},[_c('label',[_vm._v("Phone Number:")])]),_c('b-col',{attrs:{"sm":"12"}},[_c('vue-tel-input',_vm._b({staticClass:"w-100 f-14",attrs:{"disabled":_vm.$store.state.user.user.team_member.role !== 'Admin' &&
                                _vm.$store.state.user.user.team_member.role !== 'Operations'},on:{"validate":_vm.checkNum},model:{value:(_vm.company[0].phone),callback:function ($$v) {_vm.$set(_vm.company[0], "phone", $$v)},expression:"company[0].phone"}},'vue-tel-input',_vm.bindProps,false)),(_vm.errors && _vm.errors.phone)?_c('p',{staticClass:"field-error mb-lg-3"},[_vm._v(" "+_vm._s(_vm.errors.phone[0])+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"buttons text-left"},[_c('b-button',{staticClass:"mt-3 cancel",attrs:{"btn":"","to":"/"}},[_vm._v("Close")]),(_vm.$store.state.user.user.team_member.role === 'Admin' ||
                        _vm.$store.state.user.user.team_member.role === 'Operations')?_c('b-button',{staticClass:"ml-3 mt-3 save",attrs:{"btn":"","variant":"primary"},on:{"click":_vm.updateCompanyData}},[_vm._v("Save")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }